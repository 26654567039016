import React from 'react';
// import Tilt from 'react-parallax-tilt';

// https://github.com/picturepan2/devices.css/tree/master/src
import './index.scss';
import ScreenShot from './device-menu.svg';
import ScreenplifyShot from './screenplify-app-splash.svg';
import { ReactComponent as Playstore } from './Google_Play_Store_badge_EN.svg';
import { ReactComponent as Appstore } from './aivalable-on-the-app-store-2.svg';

const MobileApp = () => {
    return (
        <>
            <section className="section py-6 my-6 position-relative">
                <div className="bg-gradient-primary pattern position-relative">
                    <div className="container position-relative z-index-1">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6 order-lg-2 text-center text-lg-start py-6 py-lg-0">
                                <p className="lead text-white mb-0 opacity-50">Mobile App</p>
                                <h2 className="text-white display-6 mb-4">Coming Soon</h2>
                                <p className="lead text-white pe-0 pe-lg-6">The app you all have been waiting for! Take control of your digital signage solution. Control, manage and monitor where ever you are.</p>

                                <div className="pe-none opacity-50 d-flex align-items-center justify-content-center justify-content-lg-start">
                                    <div className="app-btn me-3">
                                        <Appstore />
                                    </div>
                                    <div className="app-btn">
                                        <Playstore />
                                    </div>
                                </div>

                            </div>

                            <div className="col-12 col-lg-6 d-flex align-items-start justify-content-center justify-content-lg-end">
                                
                                    <div className="position-relative w-100" style={{paddingTop: '30em'}}>
                                        <div className="device-wrap justify-content-center justify-content-lg-end">
                                            
                                            <div className="devices w-100 d-flex align-items-center justify-content-center justify-content-lg-end">

                                                <div className="device device-google-pixel device-black d-none d-md-block me-4">
                                                    <div className="device-frame">
                                                    <img className="device-content" src={ScreenplifyShot} alt="screenshot mobile app digital signage screenplify" />
                                                    </div>
                                                    <div className="device-stripe"></div>
                                                    <div className="device-header"></div>
                                                    <div className="device-sensors"></div>
                                                    <div className="device-btns"></div>
                                                    <div className="device-power"></div>
                                                </div>
                                                
                                                {/* <Tilt glareEnable={true} glareMaxOpacity={0.8} glareColor="#ffffff" glarePosition="bottom" glareBorderRadius="20px"> */}
                                                <div className="device device-iphone-x me-lg-5">
                                                    <div className="device-frame">
                                                        <img className="device-content" src={ScreenShot} alt="screenshot mobile app digital signage screenplify" />
                                                    </div>
                                                    <div className="device-stripe"></div>
                                                    <div className="device-header"></div>
                                                    <div className="device-sensors"></div>
                                                    <div className="device-btns"></div>
                                                    <div className="device-power"></div>
                                                </div>
                                                {/* </Tilt> */}

                                            </div>
                                            
                                        </div>
                                    </div>
                                
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
 
export default MobileApp;