import React from 'react'

import useTitle from '../../hooks/useTitle';
import CallAction from '../../components/CallAction';
import PageHeader from '../../components/PageHeader';



const Partners = () => {
    useTitle('Our Partners');

    return (
        <>
            <PageHeader
                color="info"
                title="Our Partner Program"
                text="We’re looking for partners who share our vision, culture and passion for Digital Signage, to work together to deliver the best solutions to the customer."
                image="/pexels/pexels-photo-3184465.jpeg"
            />


            <section className="section py-6">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto text-center pt-5 pb-4">
                            <h2 className="display-6 text-dark">Key Benefits</h2>
                            <p className="lead">Screenplify's software bundled with the latest hardware technologies and other services are all aimed on achieving one objective; Making Digital Signage a Breeze!</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-9 mx-auto">
                            <div className="row row-cols-1 row-cols-md-3 g-md-5 justify-content-center">
                                
                                { benefits.map((item, index) => (
                                    <div className="col" key={index}>
                                        <div className="card border-0 text-center">
                                            <div className="card-body">
                                                <Icon bg="bg-gradient-info a-style" icon={item.icon} />
                                                <h4 className="text-dark">{item.title}</h4>
                                                <p>{item.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                    

                </div>
            </section>

            <section className="section py-6 bg-light">
                <div className="container pb-0 pb-md-6">
                    <div className="row">
                        <div className="col-lg-6 mx-auto text-center pt-5 pb-4">
                            <h2 className="display-6 text-dark">Valued Partners</h2>
                            <p className="lead">Our partners have worked with us to deliver ever more innovative, effective and successful projects and their support has been critical in achieving our objectives.</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-9 mx-auto">

                            <div className="row row-cols-2 row-cols-md-4 g-md-5 justify-content-center">
                                {
                                    logos.map((item, index) => (
                                        <div className="col mb-3" key={index}>
                                            <div className="card h-100 shadow-sm border-0 p-2">
                                                <img src={process.env.PUBLIC_URL + item.image} alt="" className="card-img-top" />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>

                        </div>
                    </div>

                </div>
            </section>


            <section className="section py-6">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto text-center pt-5 pb-4">
                            <h2 className="display-6 text-dark">Partner Programs</h2>
                            <p className="lead">Screenplify has three partner programs designed for you to deliver State-of-the-Art Digital Signage Solutions to your customers.</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-9 mx-auto">
                            <div className="row row-cols-1 row-cols-md-3 g-md-5 justify-content-center">

                                {programs.map((item, index) => (
                                    <div className="col" key={index}>
                                        <div className="card border-0 text-center">
                                            <div className="card-body">
                                                <Icon bg={item.bg} icon={item.icon} />
                                                <h4 className="text-dark">{item.title}</h4>
                                                <p>{item.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>

                </div>
            </section>


            <section className="section py-6">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto text-center pt-5 pb-4">
                            <h2 className="display-6 text-dark">Choose a Program</h2>
                            <p className="lead">Choose the program that is right for your business and is consistent with your needs.</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-9 mx-auto">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <td></td>
                                        {programs.map((item, index) => (
                                            <td className="text-center" key={index}>
                                                <Icon bg={item.bg} icon={item.icon} />
                                                <p>{item.title}</p>
                                            </td>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr className="bg-light">
                                        <td colSpan="4"><h5 className="m-0 py-3 text-gradient text-primary">Benefits</h5></td>
                                    </tr>
                                    {
                                        matrixBenefits.map((item, index) => (
                                            <tr key={index}>
                                                <td className="align-middle">{item[0]}</td>
                                                <td><Mark value={item[1]}/></td>
                                                <td><Mark value={item[2]}/></td>
                                                <td><Mark value={item[3]}/></td>
                                            </tr>
                                        ))
                                    }
                                    <tr className="bg-light">
                                        <td colSpan="4"><h5 className="m-0 py-3 text-gradient text-warning">Requirements</h5></td>
                                    </tr>
                                    {
                                        matrixRequirements.map((item, index) => (
                                            <tr key={index}>
                                                <td className="align-middle">{item[0]}</td>
                                                <td><Mark value={item[1]} /></td>
                                                <td><Mark value={item[2]} /></td>
                                                <td><Mark value={item[3]} /></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </section>

            <CallAction wrapperClass="mt-6" />

        </>
    );
}

export default Partners;


const Mark = ({value=false}) => {
    return (
        <div className="text-center">
            <i className={`mdi ${value ? 'mdi-check text-gradient text-success' : 'mdi-checkbox-blank-circle text-muted opacity-25'} mdi-24px`}></i>
        </div>
    )
}

const Icon = ({ bg = "bg-gradient-primary", icon = "mdi-pencil" }) => {
    return (
        <div className="p-3 text-center">
            <div className={`icon-shape ${bg} text-white mx-auto`}>
                <i className={`mdi ${icon} mdi-24px`}></i>
            </div>
        </div>
    )
}

const programs = [
    {
        title: 'Referral Partners',
        desc: 'Referral partners can refer possible leads by registering the lead with Screenplify. Presales customer interaction & support are not required!Screenplify will offer a commission if the lead is converted into a sales.',
        icon: 'mdi-account-switch',
        bg: 'bg-gradient-info a-style'
    },
    {
        title: 'Reseller Partners',
        desc: 'Our reseller partners integrate Screenplify’s innovative digital signage products with content and other services, providing a complete solution for a customer.',
        icon: 'mdi-account-group',
        bg: 'bg-gradient-primary'
    },
    {
        title: 'Technology Partners',
        desc: 'Our premier technology partners have the ability to support our suite of our digital signage solutions with certified hardware. This gives our customers peace of mind that all parts of their system will work together smoothly.',
        icon: 'mdi-account-network',
        bg: 'bg-gradient-warning'
    },
]

const benefits = [
    {
        title: 'Increased Scalability',
        desc: 'Going from 1 screen to 100 screens or more is now a breeze, just pack, ship and plug-in to play.',
        icon: 'mdi-chart-bubble'
    },
    {
        title: 'Unattended Installations',
        desc: 'No technician, plane tickets or petrol required, meaning a cheaper and a more cost effictive solution.',
        icon: 'mdi-monitor-share'
    },
    {
        title: 'Seamless Support',
        desc: 'Hassle Free / Seamless Support: One-to-one replacement for faulty units. (T&C Apply)',
        icon: 'mdi-headset'
    },
    {
        title: 'Profit Margins',
        desc: 'Provide partners a ready market and the potential for strong margins and recurring revenues.',
        icon: 'mdi-currency-usd-circle'
    },
    {
        title: 'Satisfaction Guaranteed',
        desc: 'Screenplify guarantees usage and compatibility satisfaction of its products.',
        icon: 'mdi-emoticon-outline'
    },
    {
        title: 'Proven and Reliable',
        desc: 'Deployed nationwide and overseas, the word of our premium quality spreads quick.',
        icon: 'mdi-arm-flex'
    },
]

const logos = [
    { image: `/logos/intel.jpg` },
    { image: `/logos/giada.jpg` },
    { image: `/logos/lg.jpg` },
    // { image: `/logos/armodilo.jpg` },
    { image: `/logos/osd.jpg` },
    { image: `/logos/ctis.jpg` },
    { image: `/logos/omniphics.jpg` },
    { image: `/logos/tpm.jpg` },
    { image: `/logos/diverge.jpg` },
]


const matrixBenefits = [
    ['Referral Fees', true, false, false],
    ['Discount', true, true, true],
    ['Newsletter', true, true, true],
    ['Use Screenplify URL , LOGO', false, true, false],
    ['Online Product Training', false, true, true],
    ['Online Free Guide & Worksheet', false, true, true],
    ['Demo Account', false, true, true],
    ['30 Days Trials', false, false, true],
    ['Web branding', false, false, true],
    ['White Label', false, false, true],
]

const matrixRequirements = [
    ['Register Online', true, true, true],
    // ['Sign Agreement', false, true, true],
    ['Provide support to customer', false, true, true],
    ['Partner Product Training', false, true, true],
    ['Managed End Customer billing', false, true, true],
    ['Actively promote Screenplify', false, true, false],
]