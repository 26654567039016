import React from 'react';

import WaveLines from '../../components/Waves/Lines';

const ScreenplifyOS = () => {
    return (
        <>
            <section className="section py-6 position-relative overflow-hidden">

                <WaveLines dark={true} opacity="opacity-25" />

                <div className="container pt-6 position-relative">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 d-flex justify-content-center flex-column">

                            <div className="p-4 my-sm-0 my-sm-6 mt-8 mb-5">
                                <div className="text-center">
                                    <h2 className={`display-5 text-dark`}>Screenplify<span className="text-gradient text-primary">OS</span></h2>
                                    <p className="lead">A Digital Signage <span className="text-gradient text-primary">Operating System</span> built for high performance, better security and effortless deployment.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>



            <section className="section py-6">
                <div className="container py-4">
                    <div className="row">
                        <div className="col-lg-6 mx-auto text-center pt-5 pb-4">
                            <h2 className="display-6 text-dark">Features</h2>
                            <p className="lead mb-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo, ex!</p>
                        </div>
                    </div>

                    <ul>
                        <li>User-centric design, reduce dependency of IT proffessionals on site.</li>
                        <li>designed so that user do not have to interact with the OS physically but via backend</li>
                        <li>Automoated and remotely managed</li>
                        <li>Plug and play </li>
                        <li>Consolidating the stress of managing multiple vendors for one solution</li>
                        <li>Secured device</li>
                        <li>Linux kernel at the core</li>
                        <li>Chrome browser on top</li>
                        <li>Enable for a quick deployment</li>
                        <li>Built for Screenplify by Screenplify</li>
                        <li>optimised for digital signage without the bulk of other operating systesm like android, windows or even chrome os</li>
                        <li>continoulsy updated, secured against new threats</li>
                        <li>cheap licensing</li>
                        <li>fully cutomisable</li>
                        <li>developed in-house</li>
                    </ul>
                </div>
            </section>
        </>
    );
}
 
export default ScreenplifyOS;