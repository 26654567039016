import React, { useEffect, useRef } from 'react';

import './index.scss';

const PluginHighlight = ({ className="" }) => {
    const parentNode = useRef(null);
    const canvasNode = useRef(null);
    const URL = 'https://plugin.screenplify.com/c/embed/instagram/eyJ1bmlxdWVfaWQiOiJjOTNhZDhhOTI3YjAxY2VlMjJhYWYzODVhMjFkNTY5ZCJ9';

    useEffect(() => {

        window.addEventListener("resize", handleScale);

        handleScale();

        return () => {
            window.removeEventListener("resize", handleScale);
        }

    }, []);


    const handleScale = () => {
        const parentWidth = parentNode.current.offsetWidth - 30; // 30 for padding
        const canvasWidth = canvasNode.current.offsetWidth;
        const canvasHeight = canvasNode.current.offsetHeight;
        const scale = ((parentWidth * 100) / canvasWidth);
        const height = (canvasHeight * scale) / 100;

        parentNode.current.style.height = `${height}px`;
        canvasNode.current.style.transform = `scale(${scale / 100})`;
    }

    return (
        <>
            <div className={className} ref={parentNode}>
                <div className="laptop-animation" ref={canvasNode}>
                    <div className="screen-base">
                        <div className="screen-wrap">
                            <iframe src={URL} title="frame" frameBorder="0" seamless className="screen-frame"></iframe>
                        </div>
                    </div>
                    <div className="laptop"></div>
                </div>
            </div>
        </>
    );
}
 
export default PluginHighlight;