import React from 'react';
import WaveLines from '../Waves/Lines';

import './index.scss';

import Mockup1 from './svgs/mockup-card1.svg';
import Mockup2 from './svgs/mockup-card2.svg';
import Mockup3 from './svgs/mockup-content.svg';

const Hero = () => {
    return (
        <div className="hero-section position-relative overflow-hidden">
            
            <WaveLines dark={true} opacity="opacity-25" />

            <div className="container">
                <div className="row">

                    <div className="col-12 col-lg-4 my-auto">
                        <div className="pt-6 pt-md-6 pt-lg-0 pb-5 pb-lg-0 text-center text-lg-start">
                            <h4>Enterprise Grade</h4>
                            <h1 className="text-gradient text-warning display-4">All-In-One Digital Signage Solution</h1>
                            <p className="lead">Built for Speed, Simplicity, and Security. For all Screens, any size, any resolution, any orientation</p>
                        </div>
                    </div>

                    <div className="col-12 col-lg-8 d-none d-md-block">

                        <div className="mockups-wrap">
                            <div className="mockup1 shadow-primary bg-gradient-primary inverse">
                                <img src={Mockup1} alt="" className="img-fluid" />
                            </div>
                            <div className="mockup2 shadow-primary bg-gradient-info a-style ">
                                <img src={Mockup2} alt="" className="img-fluid" />
                            </div>
                            <div className="mockup3 shadow-primary">
                                <img src={Mockup3} alt="" className="img-fluid" />

                                <div className="floating-component"></div>
                            </div>
                            <div className="mockup4 shadow-primary">
                                <div className="ratio ratio-16x9">
                                    <video src={`${process.env.PUBLIC_URL}/videos/showcase.mp4`} muted autoPlay playsInline loop ></video>
                                </div>
                            </div>
                            <div className="mockup5 shadow-primary overflow-hidden"></div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    );
}
 
export default Hero;