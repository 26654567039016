import React from 'react';

import useTitle from '../../hooks/useTitle';
import CallAction from '../../components/CallAction';
import PageHeader from '../../components/PageHeader';


const Usecases = () => {
    useTitle('Use Cases')
    return (
        <>
            <PageHeader
                color="info"
                title="Digital Signage for Every Setting"
                text="Screenplify digital signage can be effectively used to enhance communication with your target audience. Be it show-stopping video walls or vibrant touch screen displays, seize the opportunity to influence and engage your audience at point of sale, point of wait, and point of transit environments."
                image="/pexels/pexels-photo-1058275.jpeg"
            />


            <section className="section py-6 bg-light mt-md-6">
                <div className="container py-4">
                    <div className="row">
                        <div className="col-12 col-md-7 d-flex justify-content-center flex-column order-md-2">
                            <h2 className="text-dark">Retail Outlets; Malls, Hypermarkets, Shop Lots</h2>
                            <p className="lead">Strategically placing digital signage at points of sales can positively influence customers' purchase decisions, which could, in turn, boost sales. With our system's hassle-free content update capabilities, it is easy for retailers to advertise current in-store promotions and discounts. Also, scheduled content would come in handy for time-sensitive content such as lunch promotions, or happy hour deals.</p>
                        </div>
                        <div className="col-12 col-md-5 order-md-1">
                            <img src={`${process.env.PUBLIC_URL}/use-cases/digital-signage-hypermarket.jpeg`} className="img-fluid rounded-3 shadow-primary" alt="digital signage use cases transportation" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section py-6">
                <div className="container py-4">
                    <div className="row">
                        <div className="col-12 col-md-7 d-flex justify-content-center flex-column">
                            <h2 className="text-dark">Large Scale Events; Exhibitions, Expos, Conferences, Trade Shows</h2>
                            <p className="lead">Add a smart, professional touch to your events by furnishing them with vibrant, display screens that show your audience the information they need such as conference talk schedules, speaker profiles, or booth directory.</p>
                        </div>
                        <div className="col-12 col-md-5">
                            <img src={`${process.env.PUBLIC_URL}/use-cases/digital-signage-events.jpg`} className="img-fluid rounded-3 shadow-primary" alt="digital signage use cases events" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section py-6 bg-light">
                <div className="container py-4">
                    <div className="row">
                        <div className="col-12 col-md-7 d-flex justify-content-center flex-column order-md-2">
                            <h2 className="text-dark">Property Showcases and Roadshows</h2>
                            <p className="lead">Engage your potential investors by displaying eye-catching, high-resolution images of your property on an interactive, information kiosk.</p>
                        </div>
                        <div className="col-12 col-md-5 order-md-1">
                            <img src={`${process.env.PUBLIC_URL}/use-cases/digital-signage-property-showcase.jpg`} className="img-fluid rounded-3 shadow-primary" alt="digital signage use cases property" />
                        </div>
                    </div>
                </div>
            </section>


            <section className="section py-6">
                <div className="container py-4">
                    <div className="row">
                        <div className="col-12 col-md-7 d-flex justify-content-center flex-column">
                            <h2 className="text-dark">F&B Outlets; Restaurants, Cafés</h2>
                            <p className="lead">The F&B industry is a very competitive industry and to stay ahead of the competition, many fnb businesses use digital signage to boost their brand. By using signage screens instead of static signage posters, menu displays and promotion material can be further beautified and any required menu changes will be a breeze.</p>
                        </div>
                        <div className="col-12 col-md-5">
                            <img src={`${process.env.PUBLIC_URL}/use-cases/digital-signage-cafe.jpg`} className="img-fluid rounded-3 shadow-primary" alt="digital signage use cases food outlets" />
                        </div>
                    </div>
                </div>
            </section>


            <section className="section py-6 bg-light">
                <div className="container py-4">
                    <div className="row">
                        <div className="col-12 col-md-7 d-flex justify-content-center flex-column order-md-2">
                            <h2 className="text-dark">Public Areas; Hotels, Universities, Hospitals, Offices</h2>
                            <p className="lead">Information such as directories, business/office listings, and schedules are essential in public buildings. Both visitors and employees would be pleasantly satisfied if this information was readily available and easily accessable. Information such as events or announcements can change frequently so the best way to portray these in an environment-friendly manner is through digital signage placed in common areas.</p>
                        </div>
                        <div className="col-12 col-md-5 order-md-1">
                            <img src={`${process.env.PUBLIC_URL}/use-cases/digital-signage-open-space.jpg`} className="img-fluid rounded-3 shadow-primary d-block mx-auto" alt="digital signage use cases public areas" />
                        </div>
                    </div>
                </div>
            </section>


            <section className="section py-6">
                <div className="container py-4">
                    <div className="row">
                        <div className="col-12 col-md-7 d-flex justify-content-center flex-column">
                            <h2 className="text-dark">Transportation Hubs; Train Stations, Airports, Bus Terminals</h2>
                            <p className="lead">The best use for digital signage in transport hubs is to display essential travel information such as travel schedules and bus/train routes. Real-time information such as schedule delays or route changes can be easily communicated through digital signage.</p>
                            <p className="lead">Transport hubs tend to be a point of transit or a point of wait for many. Placing digital signage in these areas can lighten up the mood for often stressed out commuters by engaging them with pleasant and interesting content.</p>
                        </div>
                        <div className="col-12 col-md-5">
                            <img src={`${process.env.PUBLIC_URL}/use-cases/digital-signage-airport.jpg`} className="img-fluid rounded-3 shadow-primary" alt="digital signage use cases transportation" />
                        </div>
                    </div>
                </div>
            </section>


            <CallAction />
        </>
    );
}

export default Usecases;