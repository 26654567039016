import React from 'react';
import './Wreath.scss';

export default function Wreath({ primary, secondary }) {
	return (
		<div className="wreath">
			<div className="text">
				<div className="secondary">{ secondary }</div>
				<div className="primary">{ primary }</div>
				<div className="stars"></div>
			</div>
		</div>
	)
}