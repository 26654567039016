import React from 'react'
import { SocialLinks } from '../../Resources';

const CallAction = ({ wrapperClass = ""}) => {
    return (
        <section className={`section pt-6 pb-4 bg-gradient-dark pattern ${wrapperClass}`}>
            {/* <div className="container text-center text-md-start">
                <div className="row">
                    <div className="col-md-6">
                        <h2 className="text-white display-6"><span className="text-gradient text-warning">Look No Further!</span> We've Got Your Digital Signage Needs Coverd.</h2>
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-center justify-content-md-end">
                        <a href='/' className="btn btn-primary bg-gradient-primary border-0 btn-lg text-white px-4">Read More</a>
                    </div>
                </div>
            </div> */}
            <div className="container text-center">
                <h2 className="display-6 text-gradient text-warning">Look No Further!</h2>
                <h2 className="text-white display-6 mb-6">We've Got Your Digital Signage Needs Covered.</h2>

                <div className="row text-white">
                    <div className="col-12 col-md-4">
                        <CallIcon bg="bg-gradient-info a-style"/>
                        <p className="lead pt-4">Ounch Sdn Bhd <small>(988628-w)</small><br/>#06-01, Wisma Minlon, <br />Batu 12, Lebuhraya Sg Besi, <br />43300 Seri Kembangan, <br />Selangor, Malaysia</p>
                    </div>

                    <div className="col-12 col-md-4">
                        <CallIcon bg="bg-gradient-primary" icon="mdi-clock"/>
                        <p className="lead pt-4">Mon - Fri : 8.30am to 6pm</p>
                    </div>

                    <div className="col-12 col-md-4">
                        <CallIcon bg="bg-gradient-success" icon="mdi-chat-processing"/>
                        <p className="lead pt-4">Email : info@screenplify.com <br />Call : +6017 505 0722 <br />Call : +603 9213 1337 ext 1002</p>
                    </div>
                </div>
            </div>

            <hr className="on-dark" />

            <div className="container">
                <ul className="nav justify-content-center">
                    <li className="nav-item">
                        <a className="nav-link text-white" href={SocialLinks.facebook} target="_blank" rel="noreferrer">
                            <i className="mdi mdi-36px mdi-facebook"></i>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link text-white" href={SocialLinks.instagram} target="_blank" rel="noreferrer">
                            <i className="mdi mdi-36px mdi-instagram"></i>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link text-white" href={SocialLinks.twitter} target="_blank" rel="noreferrer">
                            <i className="mdi mdi-36px mdi-twitter"></i>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link text-white" href={SocialLinks.youtube} target="_blank" rel="noreferrer">
                            <i className="mdi mdi-36px mdi-youtube"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </section>
    );
}
 
export default CallAction;

const CallIcon = ({ bg="bg-gradient-info", icon="mdi-map-marker" }) => {
    return (
        <div className={`icon-shape lg ${bg} text-white mx-auto rounded-pill`}>
            <i className={`mdi ${icon} mdi-36px`}></i>
        </div>
    )
}