import React from 'react';
import CallAction from '../../components/CallAction';
import PluginHeader from '../../components/PluginHeader';
import PluginHighlight from '../../components/PluginHighlight';
import PluginItem from '../../components/PluginItem';

import useTitle from '../../hooks/useTitle';
import ArrayChunk from '../../utils/ArrayChunk';

const Plugins = () => {
    useTitle('Our Plugins');

    return (
        <>
            <PluginHeader />

            <section className="section py-6">
                <div className="container py-md-6">

                    <div className="row">
                        <div className="col-lg-6 mx-auto text-center pt-5 pb-4">
                            <h5>Highlight</h5>
                            <h2 className="display-6 mb-4 text-gradient text-info">Social Plugin</h2>
                       </div>
                    </div>

                    <div className="row align-items-center">

                        <PluginHighlight className="col-12 col-lg-7" />

                        <div className="col-12 col-lg-5">
                            <p className="lead">Your social content on autoplay. Capture, collect and display your Facebook, Twitter, Instagram and user-generated hashtag posts on your Screenplify Screens.</p>
                            <p className="lead">It creates trust between you and the customers, increase conversions, builds your reputation and best of all it is visually striking.</p>
                        </div>
                    </div>

                </div>
            </section>


            <section className="section py-6 bg-light diagonal-separator">
                <div className="container py-6">

                    <div className="row py-5">
                        <div className="col-lg-6 mx-auto text-center pb-4">
                            <h2 className="display-6 mb-5 text-dark">Plugins</h2>
                            <p className="lead">Screenplify has quickly become well-known as a trusted partner of many of the regions key public and private organizations. Check out some of for their digital signage needs.</p>
                        </div>
                    </div>

                    {
                        ArrayChunk(pluginList, 3).map((chunk, index) => (
                            <div key={index} className="row justify-content-center">
                                {
                                    chunk.map((item, idx) => (
                                        <PluginItem {...item} key={idx} />
                                    ))
                                }
                            </div>
                        ))
                    }


                </div>
            </section>


            <CallAction />                  

        </>
    );
}
 
export default Plugins;


const pluginList = [
    { name: 'spark', text: 'Display vibrant, smart, and detail-oriented agenda boards and conference programmes for events.', image: `/public/plugins/spark-logo.jpg`},
    { name: 'amplify', text: 'Play and queue high-quality audio across your screens in an order that fits all audible needs.', image: `/public/plugins/amplify-logo.jpg`},
    { name: 'azan', text: 'Display the Islamic Prayer Times in a sensational design.', image: `/public/plugins/azan-logo.jpg`},
    { name: 'mapbox', text: 'A smart navigation system that lets you design and cruise maps using impeccable integration.', image: `/public/plugins/mapbox-logo.jpg`},
    { name: 'mapbox3d', text: 'The smart navigation system gets a kick. Cruise your maps in 3D and give your customers the best in Wayfinding.', image: `/public/plugins/mapbox-3d.jpg`},
    { name: 'lottie', text: 'Easily add and play Lottie animations on your Screenplify devices.', image: `/public/plugins/Lottie.jpg`},
    { name: 'weather', text: 'Check the clouds and show weather updates using this powerful plugin.', image: `/public/plugins/weather-logo.jpg`},
    { name: 'Social video', text: 'Stream adaptive YouTube, Vimeo and Facebook videos into your screens.', image: `/public/plugins/social-video.jpg`},
    { name: 'social albums', text: 'Capture, collect and display your Facebook, Twitter, Instagram and user-generated hashtag posts on your screens.', image: `/public/plugins/Social-Albums.jpg`},
    { name: 'multi layouts', text: 'Choose up to 4 layouts to be shown on a single screen. A bit crowded but can be useful in certain scenarios.', image: `/public/plugins/multi-layouts.jpg`},
    { name: 'analog clock', text: 'The classic clock for the old-school. It provides a light and dark mode. Customizable on request.', image: `/public/plugins/Analog-Clock.jpg`},
]