import React from 'react'
import { Switch, Route, Redirect } from "react-router-dom";
import Footer from './components/Footer';

import MainNav from './components/MainNav';
import Contact from './views/Contact';
import DecisionGuide from './views/DecisionGuide';
import Hardware from './views/Hardware';

import Home from './views/Home';
import Legal from './views/Legal';
import Partners from './views/Partners';
import Plugins from './views/Plugins';
import Portfolio from './views/Portfolio';
import ScreenplifyOS from './views/ScreenplifyOS';
import Software from './views/Software';
import Usecases from './views/Usecases';

const App = () => {
	return (
		<>
			<MainNav />

			<Switch>
				
				<Route path="/os">
					<ScreenplifyOS />
				</Route>
				
				<Route path="/software">
					<Software />
				</Route>
				
				<Route path="/hardware">
					<Hardware />
				</Route>
				
				<Route path="/usecases">
					<Usecases />
				</Route>
				
				<Route path="/decision-guide">
					<DecisionGuide />
				</Route>
				
				<Route path="/portfolio">
					<Portfolio />
				</Route>
				
				<Route path="/partners">
					<Partners />
				</Route>
				
				<Route path="/plugins">
					<Plugins />
				</Route>
				
				<Route path="/legal">
					<Legal />
				</Route>

				<Route path="/eula">
					<Redirect to="legal/eula" />
				</Route>
				
				<Route path="/privacy">
					<Redirect to="legal/privacy" />
				</Route>
				
				<Route path="/contact">
					<Contact />
				</Route>

				<Route path="/">
					<Home />
				</Route>

			</Switch>

			<Footer />
		</>
	);
}
 
export default App;
