import React from 'react';

const Warrenty = () => {
    return (
        <div>
            <div className="card border-0 shadow-lg">
                <div className="card-header bg-gradient-primary p-5">
                    <h2 className="text-white display-6">Warrenty & Returns</h2>
                    <p className="text-white lead">Last Updated: 01 November, 2021</p>
                </div>

                <div className="card-body p-5">

                    <h4 className="my-4">Our 365 Day Warrenty</h4>
                    
                    <p>Ounch Sdn Bhd warrants the Screenplify Intel Compute Stick and Screenplify NUC to be free of defects in material and workmanship when used as intended for a period of three hundred sixty five (365) days from the original date of purchase.</p>
                    <p>This limited warranty is extended to the original purchaser only, and excludes coverage of any damage resulting from improper use or handling, accidental damage, modifications or unauthorized repairs, normal wear &amp; tear during use as intended, or other causes that are not defects in material and workmanship.</p>
                    <p>In case of a covered defect, you must return the product and purchase receipt to Ounch Sdn Bhd to obtain warranty coverage. Ounch Sdn Bhd will repair or replace the defective product, at its sole discretion. This limited warranty extends only to authentic Screenplify Intel Compute Stick and Screenplify NUC sold by Ounch Sdn Bhd or its authorized resellers.</p>
                    <p>This product is otherwise sold as-is with all faults.  Ounch Sdn Bhd. specifically disclaims all other warranties, conditions, representations and the provision of or failure to provide any support or other service, information, software, or related content through the product. This limited warranty excludes all other express or implied warranties of any type, all of which are specifically disclaimed.</p>
                    <p>Any warranty imposed by operation of law, or course of trade or dealing, shall be limited to the three hundred sixty five (365) day term of this limited warranty. Ounch Sdn Bhd’s liability for any direct or indirect, incidental or consequential damages arising out of the use of the product, or the failure or inability to use the product shall be limited to the original purchase price of the product.  No person may change or expand the terms of this limited warranty.</p>
                    <p>Some jurisdictions do not allow a limitation on implied warranties or the amount or type of damages, so these limitations may not apply to you.  This limited warranty gives you specific legal rights, and you may have additional rights that may vary.</p>

                    <h4 className="my-4">Return Policy</h4>
                    <p>In the case that the Screenplify Intel Compute Stick or Screenplify NUC device was delivered to you in a faulty condition, we accept one-to-one unit exchange request within seven (7) days of the delivery date.</p>
                    <p>Please <a href="mailto:support@screenplify.com">contact us</a> to initiate the exchange process for your faulty Screenplify Intel Compute Stick or Screenplify NUC and your request will be processed within 7-10 business days of receipt at our warehouse.</p>

                </div>

            </div>
        </div>
    );
}
 
export default Warrenty;