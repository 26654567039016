import React from 'react'

import './index.scss';

import { ReactComponent as FullLogo } from '../../assets/screenplify_logo.svg';

const MadeEasy = () => {
    return (
        <section className="section py-4 position-relative" style={{zIndex:1}}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="bg-gradient-info shadow p-4 p-md-6 text-center text-white" style={{borderRadius: '1rem'}}>
                            <div className="madeeasy-logo">
                                <FullLogo />
                            </div>
                            <h1 className="display-4">Digital Signgae Made Easy</h1>
                            <p className="lead">Engage your audience with eye-catching displays for advertising or information purposes</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default MadeEasy;