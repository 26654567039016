import React from 'react';
import { Link } from 'react-router-dom';
import CallAction from '../../components/CallAction';

import PageHeader from '../../components/PageHeader';
import WaveDown from '../../components/Waves/Down';
import WaveLines from '../../components/Waves/Lines';
import WaveUp from '../../components/Waves/Up';
import useTitle from '../../hooks/useTitle';
import { ResourceLinks } from '../../Resources';

// https://cdn2.hubspot.net/hubfs/2700250/The%20Ultimate%20Guide%20to%20Digital%20Signage%20FINAL.pdf?

const DecisionGuide = () => {
    useTitle('Decision Guide');

    return (
        <>
            <PageHeader
                color="warning"
                title="Decision Guide"
                text="Digital signage is the most effective method for communicating with your audience. It captures people's attention regardless of location or application. Simply put: static banners, posters, and brochures simply aren't as engaging or effective as digital signs."
                image="/pexels/pexels-photo-5225428.jpeg"
            />

            <section className="section py-6 mt-6">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-6 mx-auto text-center pt-5 pb-4">
                            <h2 className="display-6 text-dark">What is Digital Signage?</h2>
                            <p className="lead">Digital signage is the modern-day replacement to traditional, static signage and is more cost-effective and flexible than other forms of visual communications. Digital signage attracts viewers’ attention, creates ambience, informs, educates, and influences purchase decision in point of sale, point of wait, and point of transit environments.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section py-6 bg-warning-soft">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6 text-center d-flex align-items-center">
                            <h3 className="display-6 text-dark px-0 px-lg-3 mb-5 mb-lg-0">Why Should Digital Signage Matter to You?</h3>
                        </div>

                        <div className="col-12 col-lg-6 text-center text-md-start">
                            
                            <h4 className="text-dark text-gradient text-primary mb-3">Communications</h4>
                            <p className="mb-5">Digital signage is the most effective method for communicating with your audience. Digital signage captures people's attention regardless of location or use. Let us just admit it: static banners, posters, and brochures simply aren't as engaging or effective as digital signage.</p>
                            
                            <h4 className="text-dark text-gradient text-primary 3">Statistics</h4>
                            <p className="mb-5">Through powerful calls to action and engaging visuals, digital signage can improve your marketing and promotional initiatives. If you want to improve communication with your visitors, staff, or students, digital signage will ensure your message stand out. In fact, digital signage captures the attention of 63% of people, making it more difficult to ignore.</p>
                            
                            
                            <h4 className="text-dark text-gradient text-primary mb-3">Cost</h4>
                            <p className="mb-5">And it's the cheapest it's ever been! Every year, the overall cost of digital signage has decreased. Previously, a digital signage installation would cost you easily RM10k++ (excluding TV) up front, but due to increased commoditization of parts, a fully functional digital signage project can now be completed for a merely RM2k+ (excluding TV).</p>
                        </div>

                    </div>
                </div>
            </section>

            <section className="section pb-6 bg-warning-soft">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6 text-center d-flex align-items-center order-lg-2">
                            <h3 className="display-6 text-dark px-0 px-lg-3 mb-5 mb-lg-0">Every Digital Signage Has Four Components!</h3>
                        </div>

                        <div className="col-12 col-lg-6">
                            <div className="row">

                                <div className="col-12 col-md-6 text-center text-md-start mb-3">
                                    <Icon bg="text-gradient text-warning d-none d-md-flex justify-content-start" icon="mdi-cloud-braces" />
                                    <h4 className="text-dark">Software</h4>
                                    <p>A content management system (CMS) is what allows you to display content on your Display.</p>
                                </div>

                                <div className="col-12 col-md-6 text-center text-md-start mb-3">
                                    <Icon bg="text-gradient text-warning d-none d-md-flex justify-content-start" icon="mdi-monitor-multiple" />
                                    <h4 className="text-dark">Hardware</h4>
                                    <p>These are the project's physical elements, such as the display, media player, and wall mounts (if needed).</p>
                                </div>

                                <div className="col-12 col-md-6 text-center text-md-start mb-3">
                                    <Icon bg="text-gradient text-warning d-none d-md-flex justify-content-start" icon="mdi-folder-multiple-image" />
                                    <h4 className="text-dark">Content</h4>
                                    <p>This is what your Display presents. It can be text, photos, video, calendars, and other media.</p>
                                </div>

                                <div className="col-12 col-md-6 text-center text-md-start mb-3">
                                    <Icon bg="text-gradient text-warning d-none d-md-flex justify-content-start" icon="mdi-calendar-star" />
                                    <h4 className="text-dark">Strategy</h4>
                                    <p>This is the strategy you will follow to ensure the success of your project.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section py-6">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto text-center pt-5 pb-4">
                            <h2 className="display-6 text-dark">Points for Consideration.</h2>
                            <p className="lead">Before deciding on any digital signage solution, you would want to ensure that the solution has the <a href="https://blog.screenplify.com/essentials-for-any-digital-signage-software/" className="text-reset highlight">features you would need</a> to make using it as simple as possible for you or your team.</p>
                            <p className="lead">Platform, application and support are the 3 most fundamentally important decisions that you will need to make during the inception of any digital signage project.</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-4 text-center mb-3">
                            <Icon bg="bg-gradient-info a-style my-4 d-none d-md-flex justify-content-center mx-auto" icon="mdi-code-braces-box" />
                            <h4 className="text-dark">Platform / OS</h4>
                            <p>The platform or operating system determines the stability, security, continuity and flexibility of the software and the hardware that is deployed. Popular platforms are Android, Windows and Linux.</p>
                            <p>While there are pros and cons for each, most notably Android has issues with version upgrades and security patches while Windows has major issues with stability and licensing. Linux has proven to be a reliable and robust platform.</p>
                        </div>

                        <div className="col-12 col-md-4 text-center mb-3">
                            <Icon bg="bg-gradient-info a-style my-4 d-none d-md-flex justify-content-center mx-auto" icon="mdi-monitor-dashboard" />
                            <h4 className="text-dark">Application</h4>
                            <p>There are 2 types of applications or software in the market; PC-based standalone applications and cloud-based applications. PC-based applications are mostly restrictive to the LAN or the device it is connected to. Cloud-based applications on the other hand are accessible from anywhere and independent of the any PC or network. All you will need is a browser.</p>
                            <p>It is important for the users responsible for publishing display content to be able to do so from anywhere. Specially for organizations that have multiple locations and want the same content to be on all displays at all locations at the correct times.</p>
                        </div>

                        <div className="col-12 col-md-4 text-center mb-3">
                            <Icon bg="bg-gradient-info a-style my-4 d-none d-md-flex justify-content-center mx-auto" icon="mdi-headset" />
                            <h4 className="text-dark">Support</h4>
                            <p>Support is a crucial part of customer service because you will expect to be able to get answers or help when you need it. It is important to find out the level of support that the selected vendor can provide; is it direct from the principle or is it a reseller vendor. Is the support teams based locally or internationally. Can they provide remote assistance or on-site fixes.</p>
                            <p>A key feature has to be the level of customization the vendor can provide. Every business is unique and it is impossible to find a solution that fits all. So any chosen vendor must be able to make tweaks and customization based on the customer's requirements. </p>
                        </div>
                    </div>
                </div>
            </section>


            <section className="bg-gradient-primary position-relative overflow-hidden py-6">
                <WaveLines />
                <WaveDown />

                <div className="container pt-6 position-relative" style={{zIndex: 2}}>

                    <div className="col-lg-6 mx-auto text-center pt-5 pb-4">
                        <h2 className="text-white display-6">So... Why Screenplify?</h2>
                        <p className="lead text-white opacity-75">Simply put; Screenplify combines the Best hardware with the Best Software to deliver an Enterprise-Grade, Premium Digital Signage Solution.</p>
                    </div>

                    <div className="row pt-4 mb-n4">
                        <div className="col-12 col-md-6 col-lg-3 mb-3">

                            <div className="card border-0 rounded-3 shadow-primary h-100">
                                <div className="card-body">
                                    <Icon bg="bg-gradient-primary mb-4 mt-n5 rounded-pill d-none d-md-flex justify-content-center "/>
                                    <h4 className="text-dark">Our Background</h4>
                                    <ul className="starred-list">
                                        <li>Since 2010 (10 years of excellence)</li>
                                        <li>Developed in-house (Full control of software)</li>
                                        <li><Link to="/portfolio" className="text-reset highlight">Deployed over 1000 screens</Link></li>
                                        <li><a href={ResourceLinks.reviews} className="text-reset highlight">High customer satisfaction</a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mb-3">

                            <div className="card border-0 rounded-3 shadow-primary h-100">
                                <div className="card-body">
                                    <Icon bg="bg-gradient-info a-style mb-4 mt-n5 rounded-pill d-none d-md-flex justify-content-center " icon="mdi-cloud" />
                                    <h4 className="text-dark">Our Software</h4>
                                    <ul className="starred-list">
                                        <li>Web application (All you need is a web browser)</li>
                                        <li>Choice of Self-Hosted or Cloud-Hosted</li>
                                        <li>Frequent updates and <Link to="/software" className="text-reset highlight">new features</Link></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mb-3">

                            <div className="card border-0 rounded-3 shadow-primary h-100">
                                <div className="card-body">
                                    <Icon bg="bg-gradient-warning mb-4 mt-n5 rounded-pill d-none d-md-flex justify-content-center " icon="mdi-monitor" />
                                    <h4 className="text-dark">Our Hardware</h4>
                                    <ul className="starred-list">
                                        <li><Link to="/hardware" className="text-reset highlight">INTEL® x86 Devices</Link></li>
                                        <li>Industrial grade</li>
                                        <li>3 years warranty</li>
                                        <li>Linux-based hardened OS</li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mb-3">

                            <div className="card border-0 rounded-3 shadow-primary h-100">
                                <div className="card-body">
                                    <Icon bg="bg-gradient-success mb-4 mt-n5 rounded-pill d-none d-md-flex justify-content-center " icon="mdi-chart-bar" />
                                    <h4 className="text-dark">Our Support</h4>
                                    <ul className="starred-list">
                                        <li>Direct from principle</li>
                                        <li>Local certified team</li>
                                        <li>Proven track record</li>
                                        <li>In-house development team</li>
                                        <li>24/7 monitoring</li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <WaveUp />
            </section>


            <section className="section pt-6">
                <div className="container pt-5">
                    <div className="row">
                        <div className="col-lg-6 mx-auto text-center pt-5 pb-4">
                            <p className="mb-0">More Info</p>
                            <h2 className="display-6 text-dark mb-6">Operating Systems Comparison</h2>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-12 col-md-9 mx-auto">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <td></td>
                                        {programs.map((item, index) => (
                                            <td className="text-center" key={index}>
                                                <Icon bg={item.bg} icon={item.icon} />
                                                <p>{item.title}</p>
                                            </td>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr className="bg-light">
                                        <td colSpan="4"><h5 className="m-0 py-3 text-gradient text-primary">Features</h5></td>
                                    </tr>
                                    {
                                        osFeatures.map((item, index) => (
                                            <tr key={index}>
                                                <td className="align-middle" dangerouslySetInnerHTML={{ __html: item[0]}}></td>
                                                <td><Mark value={item[1]} /></td>
                                                <td><Mark value={item[2]} /></td>
                                                <td><Mark value={item[3]} /></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>

                            <div className="py-4">
                                <p><small>1. From 2016-2019, Android has 2,395 (49.64%) vulnerabilities compared to Ubuntu 1,376 (28.52%) and Windows 10 has 1,054 (21.84%)</small></p>
                                <p><small>2. Linux was ahead of Windows every week by more than 0.5% in terms of uptime. 0.5% downtime means 50 minutes per week or 3 hrs 36 mins per month.</small></p>
                            </div>
                        </div>
                    </div>
                    

                </div>
            </section>


            <section className="section py-6">
                <div className="container pb-5">
                    <div className="row">
                        <div className="col-lg-6 mx-auto text-center pt-5 pb-4">
                            <p className="mb-0">More Info</p>
                            <h2 className="display-6 text-dark mb-6">Hardware Comparison</h2>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-12 col-md-9 mx-auto">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <td></td>
                                        {hardware.map((item, index) => (
                                            <td className="text-center" key={index}>
                                                <Icon bg={item.bg} icon={item.icon} />
                                                <p>{item.title}</p>
                                            </td>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr className="bg-light">
                                        <td colSpan="4"><h5 className="m-0 py-3 text-gradient text-primary">Features</h5></td>
                                    </tr>
                                    {
                                        hardwareFeatures.map((item, index) => (
                                            <tr key={index}>
                                                <td className="align-middle" dangerouslySetInnerHTML={{ __html: item[0] }}></td>
                                                <td><Mark value={item[1]} /></td>
                                                <td><Mark value={item[2]} /></td>
                                                <td><Mark value={item[3]} /></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>

                        </div>
                    </div>


                </div>
            </section>


            <CallAction  />

        </>
    );
}
 
export default DecisionGuide;


const Mark = ({ value = false }) => {
    const _value = value.toUpperCase();

    return (
        <div className={`text-center ${_value === 'HIGH' || _value === 'FREE' ? 'text-gradient text-primary' : '' }`}>
            {value}
        </div>
    )
}

const Icon = ({ bg = "bg-gradient-primary", icon = "mdi-pencil" }) => {
    return (
        <div className="">
            <div className={`icon-shape ${bg} text-white`}>
                <i className={`mdi ${icon} mdi-24px`}></i>
            </div>
        </div>
    )
}


const programs = [
    {
        title: 'Android',
        // desc: 'Referral partners can refer possible leads by registering the lead with Screenplify. Presales customer interaction & support are not required!Screenplify will offer a commission if the lead is converted into a sales.',
        icon: 'mdi-android',
        bg: 'bg-gradient-success mb-4 mx-auto rounded-pill d-none d-md-flex justify-content-center'
    },
    {
        title: 'Windows',
        // desc: 'Our reseller partners integrate Screenplify’s innovative digital signage products with content and other services, providing a complete solution for a customer.',
        icon: 'mdi-microsoft-windows',
        bg: 'bg-gradient-success mb-4 mx-auto rounded-pill d-none d-md-flex justify-content-center'
    },
    {
        title: 'Linux',
        // desc: 'Our premier technology partners have the ability to support our suite of our digital signage solutions with certified hardware. This gives our customers peace of mind that all parts of their system will work together smoothly.',
        icon: 'mdi-linux',
        bg: 'bg-gradient-success mb-4 mx-auto rounded-pill d-none d-md-flex justify-content-center'
    },
]

const hardware = [
    {
        title: 'INTEL® x86',
        // desc: 'Referral partners can refer possible leads by registering the lead with Screenplify. Presales customer interaction & support are not required!Screenplify will offer a commission if the lead is converted into a sales.',
        icon: 'mdi-memory',
        bg: 'bg-gradient-success mb-4 mx-auto rounded-pill d-none d-md-flex justify-content-center'
    },
    {
        title: 'Android',
        // desc: 'Our reseller partners integrate Screenplify’s innovative digital signage products with content and other services, providing a complete solution for a customer.',
        icon: 'mdi-android',
        bg: 'bg-gradient-success mb-4 mx-auto rounded-pill d-none d-md-flex justify-content-center'
    },
    {
        title: 'SoC',
        // desc: 'Our premier technology partners have the ability to support our suite of our digital signage solutions with certified hardware. This gives our customers peace of mind that all parts of their system will work together smoothly.',
        icon: 'mdi-chip',
        bg: 'bg-gradient-success mb-4 mx-auto rounded-pill d-none d-md-flex justify-content-center'
    },
]


const osFeatures = [
    ['Stability <sup>1<sup>', 'Low', 'Mid', 'High'],
    ['Security <sup>2<sup>', 'Low', 'Mid', 'High'],
    ['Long Term Support', 'Low', 'High', 'High'],
    ['Cost', 'Low', 'Mid', 'Free'],
]

const hardwareFeatures = [
    ['Parts Availability', 'High', 'Low', 'Low'],
    ['Brand Lock-In', 'Low', 'Mid', 'High'],
    ['Upgrability', 'High', 'Low', 'Low'],
    ['Cost', 'Mid', 'Low', 'Low'],
]