import React from 'react';
import WaveLines from '../Waves/Lines';

const PageHeader = ({ color = 'warning', title = '', text = '', image ='/use-cases/digital-signage-device-sunway.jpg' }) => {

    const coverImageStyles = {
        backgroundImage: `url(${process.env.PUBLIC_URL}${image})`,
        backgroundSize: 'cover',
        // borderRadius: '0 0 0 200px'
    }

    return (
        <section className="section py-6 position-relative overflow-hidden">
            <div className={`oblique position-absolute top-0 h-100 d-md-block d-none background-mask background-mask-${color} z-index-0`}>
                <div className="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" style={coverImageStyles}></div>
            </div>

            <WaveLines dark={true} opacity="opacity-25" />

            <div className="container pt-6 position-relative">
                <div className="row">
                    <div className="col-12 col-md-6 d-flex justify-content-center flex-column">

                        <div className="p-4 my-sm-0 my-sm-6 mt-8 mb-5">
                            <div className="text-center text-md-start">
                                <h2 className={`display-6 text-gradient text-${color}`}>{title}</h2>
                                <p className="lead pe-0 pe-lg-5">{text}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default PageHeader;