
export const ResourceLinks = {
    blog: 'https://blog.screenplify.com/',
    plugin: 'https://plugin.screenplify.com/',
    templates: 'https://templates.screenplify.com/',
    // reviews: 'https://reviews.screenplify.com/',
    reviews: `${process.env.PUBLIC_URL}/portfolio`,
    app: 'https://app.screenplify.com/'
}

export const ExternalReviews = {
    trustedMalaysia: 'https://www.trustedmalaysia.com/best-digital-signage-malaysia/',
    funEmpire: 'https://www.thefunempire.com/digital-signage-malaysia/',
    productNation: 'https://productnation.co/my/28431/best-digital-signage-malaysia/#p-71883'
}

export const SocialLinks = {
    facebook: 'https://www.facebook.com/screenplify',
    instagram: 'https://www.instagram.com/screenplify',
    twitter: 'https://twitter.com/screenplify',
    youtube: 'https://www.youtube.com/channel/UC6fsSD5O6VZPkkbSfbNapaA'
}