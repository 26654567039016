import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";

// Bootstrap CSS inported inside Sass
import './sass/index.scss';

// Import Bootstrap
import 'bootstrap/dist/js/bootstrap.bundle';

import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter
			basename={window.location.hostname.match('screenplify.com') ? process.env.PUBLIC_URL : '' }
			forceRefresh={true}
		>
			<App />	
		</BrowserRouter>
	</React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
