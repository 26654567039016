import React from 'react'
import WaveDown from '../Waves/Down';
import WaveLines from '../Waves/Lines';
import WaveUp from '../Waves/Up';

const DesignDeployment = () => {
    return (
        <>

        <section className="bg-gradient-primary position-relative overflow-hidden py-6">
            <WaveLines />
            <WaveDown />

            <div className="container pt-6 text-center position-relative" style={{zIndex: 2}}>

                <div className="col-lg-6 mx-auto text-center pt-5 pb-4">
                    <h2 className="text-white display-6 mb-4">Design To Deployment</h2>
                    <p className="lead text-white opacity-75">Creating screen layouts will no longer be a daunting task. We make it easy for your content layout designs go from your idea board to your digital screens without you having to rely on others. That's how pleasantly simple our user interface and creation workflow is!</p>
                </div>

                <div className="row pt-4 mb-n4">
                    <div className="col-12 col-md-6 col-lg-3 mb-3">

                        <div className="card border-0 rounded-3 shadow-primary h-100">
                            <div className="card-body">
                                <Icon />
                                <h4 className="text-dark">Power Editor</h4>
                                <p className="text-dark opacity-75">Design vibrant and dynamic, interactive/non-interactive displays for your digital signage screens with our intuitive, drag-and-drop layout editor.</p>
                            </div>
                        </div>

                    </div>
                    <div className="col-12 col-md-6 col-lg-3 mb-3">
                        
                        <div className="card border-0 rounded-3 shadow-primary h-100">
                            <div className="card-body">
                                <Icon bg="bg-gradient-info a-style" icon="mdi-cloud" />
                                <h4 className="text-dark">Content Manager</h4>
                                <p className="text-dark opacity-75">Cloud-based content manager allows for instant content updates, anytime, anywhere.</p>
                            </div>
                        </div>

                    </div>
                    <div className="col-12 col-md-6 col-lg-3 mb-3">

                        <div className="card border-0 rounded-3 shadow-primary h-100">
                            <div className="card-body">
                                <Icon bg="bg-gradient-warning" icon="mdi-monitor" />
                                <h4 className="text-dark">Stability</h4>
                                <p className="text-dark opacity-75">A reliable playback system that will display your screen designs 24 hours a day and 365 days a year.</p>
                            </div>
                        </div>

                    </div>
                    <div className="col-12 col-md-6 col-lg-3 mb-3">

                        <div className="card border-0 rounded-3 shadow-primary h-100">
                            <div className="card-body">
                                <Icon bg="bg-gradient-success" icon="mdi-chart-bar" />
                                <h4 className="text-dark">Analytics</h4>
                                <p className="text-dark opacity-75">Monitor and maintain your network of digital signage displays through visual reports on user behavior and system performance.</p>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <WaveUp />

        </section>

        </>
    );
}
 
export default DesignDeployment;

const Icon = ({bg="bg-gradient-primary", icon="mdi-pencil"}) => {
    return (
        <div className="p-3 text-center">
            <div className={`icon-shape ${bg} text-white mx-auto`}>
                <i className={`mdi ${icon} mdi-24px`}></i>
            </div>
        </div>
    )
}