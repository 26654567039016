import React from 'react'
import { Link } from 'react-router-dom';

import Boxes from './3d-nuc-rugged.jpg';

const SimpleSoftware = () => {
    return (
        <section className="section py-6">
            <div className="container py-md-6">

                <div className="row">
                    <div className="col-lg-6 mx-auto text-center pt-5 pb-4">
                        <h4>Suitable for All Digital Signage</h4>
                        <h2 className="text-gradient text-primary display-6 mb-4">Simple Software. Powerful Hardware</h2>
                        {/* <p className="lead">Screenplify's <Link className="text-reset highlight" to="/software">software</Link> is powered by INTEL® NUC and INTEL® Compute Sticks.</p> */}
                        <p className="lead">These high-performance INTEL® <Link className="text-reset highlight" to="/hardware">hardware</Link> bridges Screenplify and your digital screens. Have your digital displays all set up by simply plugging-in the device to your screen or tv, and let Screenplify do the rest.</p>
                        <img src={Boxes} className="img-fluid" alt="" />
                    </div>
                </div>

            </div>
        </section>
    );
}
 
export default SimpleSoftware;