import React from 'react';
import { Link } from 'react-router-dom';

const ContactBar = () => {
    return (
        <section className="section py-6 bg-gradient-dark pattern overflow-hidden position-relative">
            <div className="container position-relative">
                <div className="row">
                    <div className="col-12 col-lg-7">
                        <h1 className="display-6 m-0 text-white text-center text-lg-start">If you have any suggestions, comments or even feature requests, please do not hesitate to contact us!</h1>
                    </div>
                    <div className="col-12 col-lg-5 d-flex align-items-center justify-content-center justify-content-lg-end">
                        <Link to="/contact" className="btn btn-warning bg-gradient-warning border-0 btn-lg text-white px-6 py-4">Contact Us</Link>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default ContactBar;