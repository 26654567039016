import React from 'react';

const Bribary = () => {
    return (
        <div>
            <div className="card border-0 shadow-lg">
                <div className="card-header bg-gradient-primary p-5">
                    <h2 className="text-white display-6">Anti-Bribary & Anti-Corruption Policy</h2>
                    <p className="text-white lead">Last Updated: 28 September, 2016</p>
                </div>

                <div className="card-body p-5">

                    <ol>

                        <li>
                            <h4>What does your policy cover?</h4>
                            <ol>
                                <li>
                                    <p>This anti-bribery policy exists to set out the responsibilities of Ounch Sdn Bhd and those who work for us in regards to observing and upholding our zerotolerance position on bribery and corruption</p>
                                </li>
                                <li>
                                    <p>It also exists to act as a source of information and guidance for those working for Ounch Sdn Bhd. It helps them recognize and deal with bribery and corruption issues, as well as understand their responsibilities.</p>
                                </li>
                            </ol>
                        </li>

                        <li>
                            <h4>Policy statement</h4>
                            <ol>
                                <li><p>Ounch Sdn Bhd is committed to conducting business in an ethical and honest
                                    manner, and is committed to implementing and enforcing systems that
                                    ensure bribery is prevented. Ounch Sdn Bhd has zero-tolerance for bribery
                                    and corrupt activities. We are committed to acting professionally, fairly, and
                                    with integrity in all business dealings and relationships, wherever in the
                                    country we operate.</p></li>
                            </ol>
                        </li>

                        <li>
                            <h4>Who is covered by the policy?</h4>
                            <ol>
                                <li>
                                    <p>This anti-bribery policy applies to all employees (whether temporary, fixedterm, or permanent), consultants, contractors, trainees, home workers,
                                        agency staff, interns, agents or any other person or persons associated with
                                        us (including third parties), or any of our subsidiaries or their employees, no
                                        matter where they are located (within or outside of Malaysia). </p>
                                </li>
                                <li>
                                    <p>In the context of this policy, third-party refers to any individual or
                                        organization our company meets and works with. It refers to actual and
                                        potential clients, customers, suppliers, distributors, business contracts,
                                        agents, advisers, and government and public bodies – this includes their
                                        advisors, representatives and officials, politicians, and public parties.</p>
                                </li>
                                <li>
                                    <p>Any arrangements out company makes with a third party is subject to clear
                                        contractual terms, including specific provisions that require the third party to
                                        comply with minimum standards and procedures relating to anti-bribery and
                                        corruption.</p>
                                </li>
                            </ol>
                        </li>


                        <li>
                            <h4>Definition of bribery</h4>
                            <ol>
                                <li>
                                    <p>Bribery refers to the act of offering, giving, promising, asking, agreeing,
                                        receiving, accepting, or soliciting something of value or of an advantage so to
                                        induce or influence an action or decision.</p>
                                </li>
                                <li>
                                    <p>A bribe refers to any inducement, reward, or object/item of value offered to
                                        another individual in order to gain commercial, contractual, regulatory, or
                                        personal advantage.</p>
                                </li>
                                <li>
                                    <p>Bribery is not limited to the act of offering a bribe. If an individual is on the
                                        receiving end of a bribe and they accept it, they are also breaking the law.</p>
                                </li>
                                <li>
                                    <p>Bribery is illegal. Employees must not engage in any form of bribery, whether
                                        it be directly, passively (as described above), or through a third party (such as
                                        an agent or distributor). They must not bribe a foreign public official
                                        anywhere in the world. They must not accept bribes in any degree and if they
                                        an uncertain about whether something is a bribe or a gift or act of hospitality, they must seek further advice from the company’s compliance department.</p>
                                </li>
                            </ol>
                        </li>


                        <li>
                            <h4>What is and what is NOT acceptable</h4>
                            <ol>
                                <li>
                                    <p>Gifts and hospitality.
                                        Ounch Sdn Bhd accepts normal and appropriate gestures of hospitality and
                                        goodwill (whether given to/received from third parties) as long as the giving
                                        or receiving of gifts meets the following requirements:
                                        
                                        <ol>
                                            <li>It is not made with the intention of influencing the party of whom it is
                                                being given, to obtain or reward the retention of a business or a
                                                business advantage, or as an explicit or implicit exchange for favors or
                                                benefits.</li>
                                            <li>It is not made with the suggestion that a return favor is expected</li>
                                            <li>It is in compliance with local law.</li>
                                            <li>It is given in the name of the company, not in an individual’s name.</li>
                                            <li>It does not include cash or a cash equivalent (e.g. a voucher or gift
                                                certificate)</li>
                                            <li>It is appropriate for the circumstances (e.g. giving small gifts around
                                                Christmas or a small thank you to a company for helping with a large
                                                project upon completion)</li>
                                            <li>It is of an appropriate type and value and given at an appropriate
                                                time, taking into account the reason for the gift.</li>
                                            <li>It is given/received openly, not secretly.</li>
                                            <li>It is not selectively given to a key, influential person, clearly with the
                                                intention of directly influencing them</li>
                                            <li>It is not offer to, or accepted from, a government official or
                                                representative or politician or political party, without the prior
                                                approval of the company’s management.</li>
                                        </ol>
                                    </p>
                                </li>

                                <li>
                                    <p>Ounch Sdn Bhd recognizes that the practice of giving and receiving business
                                        gifts varies between countries, regions, cultures, and religions, so definitions
                                        of what is acceptable and not acceptable with inevitably differ for each.</p>
                                </li>

                                <li>
                                    <p>As good practice, gifts given and received should always be disclosed to the
                                        compliance department. Gifts from suppliers should always be disclosed.</p>
                                </li>

                                <li>
                                    <p>The intention behind a gift being given/received should always be
                                        considered. If there is any uncertainty, the advice of the compliance
                                        department should be sought.</p>
                                </li>

                                <li>
                                    <p>Facilitation Payments and Kickbacks
                                        <ol>
                                            <li>Ounch Sdn Bhd does not accept and will not make any form of
                                                facilitation payments of any nature. We recognize that facilitation
                                                payments are a form of bribery that involves expediting or facilitating
                                                the performance of a public official for a routine government action.
                                                We recognize that they tend to be made by low level officials with the
                                                intention of securing or speeding up the performance of a certain
                                                duty or action.</li>
                                            <li>Ounch Sdn Bhd does not allow kickbacks to be made or accepted. We
                                                recognize that kickbacks are typically made in exchange for a business
                                                favor or advantage.</li>
                                            <li>Ounch Sdn Bhd recognizes that, despite our strict policy on facilitation
                                                payments and kickbacks, employees may face a situation where
                                                avoiding a facilitation payment or kickback may put their/their
                                                family’s personal security at risk. Under these circumstances, the
                                                following steps must be taken:
                                                <ol>
                                                    <li>Keep any amount to the minimum.</li>
                                                    <li>Ask for a receipt, detailing the amount and reason for the
                                                        payment.</li>
                                                    <li>Create a record concerning the payment.</li>
                                                    <li>Report this incident to your line manager</li>
                                                </ol>    
                                            </li>

                                            <li>Political Contributions
                                                <ol>
                                                    <li>Ounch Sdn Bhd will not make donations, whether in cash,
                                                        kind, or by any other means, to support any political parties or
                                                        candidates. We recognize this may be perceived as an attempt
                                                        to gain an improper business advantage.</li>
                                                </ol>
                                            </li>

                                            <li>Charitable Contributions
                                                <ol>
                                                    <li>Ounch Sdn Bhd accepts (and indeed encourages) the act of
                                                        donating to charities – whether through services, knowledge,
                                                        time, or direct financial contributions (cash or otherwise) –
                                                        and agrees to disclose all charitable contributions it makes.</li>
                                                    <li>Employees must be careful to ensure that charitable
                                                        contributions are not used to facilitate and conceal acts of
                                                        bribery.</li>
                                                    <li>We will ensure that all charitable donations made are legal
                                                        and ethical under local laws and practices, and that donations
                                                        are not offered / made without the approval of the
                                                        compliance department.</li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </p>
                                </li>

                            </ol>

                        </li>


                        <li>
                            <h4>Employee Responsibilities</h4>
                            <p>
                                <ol>
                                    <li>As an employee of Ounch Sdn Bhd, you must ensure that you read,
                                        understand, and comply with the information contained within this policy,
                                        and with any training or other anti-bribery and corruption information you
                                        are given.</li>
                                    <li>All employees and those under our control are equally responsible for the
                                        prevention, detection, and reporting of bribery and other forms of
                                        corruption. They are required to avoid any activities that could lead to, or
                                        imply, a breach of this anti-bribery policy</li>
                                    <li>If you have reason to believe or suspect that an instance of bribery or
                                        corruption has occurred or will occur in the future that breaches this policy,
                                        you must notify the compliance department.</li>
                                    <li>If any employee breaches this policy, they will face disciplinary action and
                                        could face dismissal for gross misconduct. Ounch Sdn Bhd has the right to
                                        terminate a contractual relationship with an employee if they breach this
                                        anti-bribery policy.</li>
                                </ol>
                            </p>
                        </li>

                        <li>
                            <h4>Record Keeping</h4>
                            <p>
                                <ol>
                                    <li>Ounch Sdn Bhd will keep detailed and accurate financial records, and will have
                                        appropriate internal controls in place to act as evidence for all payments made.
                                        We will declare and keep a written record of the amount and reason for
                                        hospitality or gifts accepted, and given, and understand that gifts and acts of
                                        hospitality are subject to the managerial review.</li>
                                </ol>
                            </p>
                        </li>


                    </ol>

                </div>

            </div>
        </div>
    );
}

export default Bribary;