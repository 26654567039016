import React from 'react'
import AnimatedWaves from '../Waves/Animated';
// import WaveLines from '../Waves/Lines';

import './index.scss';
import '../Waves/Pattern.scss';

import { ReactComponent as Map } from './malaysia.svg';

const topOffset = 50;

const NationWide = () => {
    return (
        <section className="section bg-purple-dark diagonal-separator pattern position-relative overflow-hidden pb-6" style={{ marginTop: '-180px', paddingTop: '180px' }}>
            {/* <WaveLines /> */}
            <div className="container py-6">
                <div className="row pb-6">
                    <div className="col-12 col-lg-6 col-lg-6">
                        <h2 className="text-white display-6">Deployed Nationwide & Overseas</h2>
                        <p className="lead text-white opacity-75">Being one of the leading digital signage software solutions provider in Malaysia, the word on our premium quality products and services spreads quick.</p>
                        <p className="lead text-white opacity-75">Screenplify currently powers Digital Signage Screens in:</p>
                        <ul className="list-unstyled text-white opacity-75 ps-2">
                            <li><i className="mdi mdi-circle-medium me-1"></i>Kuala Lumpur, Wilayah Perseketuan</li>
                            <li><i className="mdi mdi-circle-medium me-1"></i>Putrajaya, Wilayah Perseketuan</li>
                            <li><i className="mdi mdi-circle-medium me-1"></i>Bukit Jalil, Wilayah Perseketuan</li>
                            <li><i className="mdi mdi-circle-medium me-1"></i>Subang Jaya, Selangor</li>
                            <li><i className="mdi mdi-circle-medium me-1"></i>Bangsar, Selangor</li>
                            <li><i className="mdi mdi-circle-medium me-1"></i>Seremban, Negeri Sembilan</li>
                            <li><i className="mdi mdi-circle-medium me-1"></i>Gong Badak, Kuala Terrengganu</li>
                            <li><i className="mdi mdi-circle-medium me-1"></i>Ipoh, Perak</li>
                            <li><i className="mdi mdi-circle-medium me-1"></i>Bukit Mertajah, Penang</li>
                            <li><i className="mdi mdi-circle-medium me-1"></i>Malacca City, Malacca</li>
                            <li><i className="mdi mdi-circle-medium me-1"></i>Pelangi Indah, Johor</li>
                            <li><i className="mdi mdi-circle-medium me-1"></i>Samariang, Kuching</li>
                            <li><i className="mdi mdi-circle-medium me-1"></i>Taman Batik, Kedah</li>
                            <li><i className="mdi mdi-circle-medium me-1"></i>Singapore</li>
                            <li>... and many more exciting projects in the pipeline</li>
                        </ul>
                    </div>
                    <div className="col-12 col-lg-6 d-none d-md-block">
                        <div className="map-container">
                            <div className="map" style={{ top: topOffset + 'px' }}>
                                <Map />
                            </div>

                            {
                                markers.map((item, index) => (
                                    <i className={`marker mdi mdi-map-marker mdi-24px ${item.color}`} key={index} style={{ ...item.style, top: `calc(${item.style.top} + ${topOffset}px)` }}></i>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>

            <AnimatedWaves />
        </section>
    );
}

export default NationWide;

const markers = [
    { color: 'text-gradient text-primary', style: { top: '14px', left: '36px' } },

    { color: 'text-gradient text-info', style: { top: '58px', left: '47px' } },
    { color: 'text-gradient text-warning', style: { top: '68px', left: '57px' } },

    { color: 'text-gradient text-warning', style: { top: '58px', left: '180px' } },
    { color: 'text-gradient text-primary', style: { top: '50px', left: '169px' } },
    { color: 'text-gradient text-info', style: { top: '60px', left: '164px' } },

    { color: 'text-gradient text-info', style: { top: '190px', left: '99px' } },
    { color: 'text-gradient text-warning', style: { top: '180px', left: '88px' } },
    { color: 'text-gradient text-primary', style: { top: '199px', left: '110px' } },
    { color: 'text-gradient text-info', style: { top: '185px', left: '113px' } },


    { color: 'text-gradient text-warning', style: { top: '210px', left: '160px' } },
    { color: 'text-gradient text-info', style: { top: '200px', left: '150px' } },
    { color: 'text-gradient text-info', style: { top: '219px', left: '170px' } },
    { color: 'text-gradient text-primary', style: { top: '205px', left: '153px' } },

    { color: 'text-gradient text-warning', style: { top: '220px', left: '110px' } },
    { color: 'text-gradient text-info', style: { top: '210px', left: '100px' } },
    { color: 'text-gradient text-info', style: { top: '229px', left: '120px' } },
    { color: 'text-gradient text-primary', style: { top: '215px', left: '123px' } },

    { color: 'text-gradient text-warning', style: { top: '210px', left: '210px' } },
    { color: 'text-gradient text-info', style: { top: '220px', left: '200px' } },
    { color: 'text-gradient text-info', style: { top: '209px', left: '220px' } },
    { color: 'text-gradient text-primary', style: { top: '215px', left: '223px' } },

    { color: 'text-gradient text-warning', style: { top: '310px', left: '235px' } },
    { color: 'text-gradient text-primary', style: { top: '306px', left: '247px' } },
    { color: 'text-gradient text-info', style: { top: '321px', left: '240px' } },

    { color: 'text-gradient text-warning', style: { top: '290px', left: '212px' } },
    { color: 'text-gradient text-primary', style: { top: '286px', left: '209px' } },
    { color: 'text-gradient text-info', style: { top: '295px', left: '205px' } },

    { color: 'text-gradient text-primary', style: { top: '211px', left: '323px' } },
    { color: 'text-gradient text-info', style: { top: '218px', left: '330px' } },
    { color: 'text-gradient text-warning', style: { top: '222px', left: '335px' } },

    { color: 'text-gradient text-primary', style: { top: '188px', left: '377px' } },
    { color: 'text-gradient text-info', style: { top: '192px', left: '369px' } },
    { color: 'text-gradient text-warning', style: { top: '190px', left: '365px' } },
]