import React from 'react'

import DesignDeployment from '../../components/DesignDeployment';
import Hero from '../../components/Hero';
import MadeEasy from '../../components/MadeEasy';
import MaxImpact from '../../components/MaxImpact';
import NationWide from '../../components/NationWide';
import SimpleSoftware from '../../components/SimpleSoftware';
import Companies from '../../components/Companies';
import Testimonials from '../../components/Testimonials';
import BlogStrip from '../../components/BlogStrip';
import CallAction from '../../components/CallAction';
import FaqSection from '../../components/FAQ';
import MobileApp from '../../components/MobileApp';

const Home = () => {
    return (
        <>
            <Hero/>
            <DesignDeployment />
            <MaxImpact />
            <SimpleSoftware />
            <MadeEasy />
            <NationWide />
            <Companies />
            <Testimonials show={6} />
            <BlogStrip />
            <CallAction wrapperClass="mt-n6" />
            <FaqSection />
            <MobileApp />
        </>
    );
}
 
export default Home;