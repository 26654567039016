import React from 'react'
import { Link } from 'react-router-dom';

import './index.scss';

// import Featues from './features.png';

const MaxImpact = () => {
    return (
        <section className="section py-6">
            <div className="container py-md-6">

                <div className="row">
                    <div className="col-lg-6 mx-auto text-center pt-5 pb-4">
                        <h4>Seamless & Simple</h4>
                        <h2 className="text-gradient text-info display-6 mb-4">Maximum Impact. Minimal Effort</h2>
                        <p className="lead">Screenplify's brilliant <Link to="/software" className="text-reset highlight">features and functionalities</Link> are all aimed at one thing: making digital signage a breeze!</p>
                        <p className="lead">Anything and everything related to Digital Signage can be easily configured according to your preference, from scheduling the content to be displayed, down to toggling your display's interactivity setting.</p>
                    </div>
                </div>

                <div className="row floating-featues row-cols-2 row-cols-md-4 row-cols-lg-6 justify-content-md-center">
                    <Floater text="Scheduled Content" />
                    <Floater text="HD Media Playback" textStyle="text-info" icon="mdi-video-high-definition mdi-36px" />
                    <Floater text="Touch Enabled" textStyle="text-success" icon="mdi-hand-pointing-up" />
                    <Floater text="Plugin Exendible" textStyle="text-warning" icon="mdi-power-plug" />
                    <Floater text="Analytics" textStyle="text-info" icon="mdi-chart-bar" />
                    <Floater text="Cloud-Based CMS" icon="mdi-cloud-braces"  />
                </div>

                {/* <div className="row">
                    <div className="col-12 col-md-6 text-center text-md-start">
                    </div>
                    <div className="col-12 col-md-6 text-center">
                        <img src={Featues} className="img-fluid" alt="" />
                    </div>
                </div> */}

            </div>
        </section>
    );
}
 
export default MaxImpact;

const mediaQuery = window.matchMedia('(min-width: 768px)');

const Floater = ({ text = "", textStyle = "text-primary", icon = "mdi-timer", className="" }) => {
    return (
        <div className={`col position-relative border-1 ${className}`}>
            <p className={`icon-floating floating bg-white ${mediaQuery.matches ? 'text-gradient' : ''} ${textStyle}`}>
                <i className={`mdi ${icon} mdi-24px`}></i>
                <span className={`m-0 d-block`}>{text}</span>
            </p>
        </div>
    )
}