import React from 'react';

import whiteWave from './waves-white.svg';
import darkWave from './waves-gray.svg';

const WaveLines = ({ dark=false, opacity="opacity-6" }) => {
    return (
        <img src={dark ? darkWave : whiteWave} alt="pattern-lines" className={`position-absolute ${opacity}`} />
    );
}
 
export default WaveLines;