import React from 'react'
import CallAction from '../../components/CallAction';
import Fancybox from '../../components/Fancybox';
import PageHeader from '../../components/PageHeader';
import Testimonials from '../../components/Testimonials';
import useTitle from '../../hooks/useTitle';

import './index.scss';



const Portfolio = () => {
    useTitle('Our Portfolio');
    
    return (
        <>
            <PageHeader
                color="warning"
                title="Our Portfolio"
                text="Our clients understand that a meaningful partnership is a two-way street in which ideas and information flow freely and routinely, based on mutual trust and respect for one another's expertise."
                image="/pexels/pexels-fauxels-3184634.jpg"
            />


            <section className="section py-6 overflow-hidden">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto text-center pt-5 pb-4">
                            <h2 className="display-6 text-dark">Case Studies</h2>
                            <p className="lead">Every project, every client is unique. Learn how and why our customers choose Screenplify as their visual communications partner. </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-9 mx-auto">
                    
                            <div className="row row-cols-1 row-cols-md-3 g-md-5 justify-content-center">
                                {
                                    caseStudies.map((item, index) => (
                                        <div className="col" key={index}>
                                            <div className="card h-100 shadow-sm border-0 mb-3">
                                                <img src={item.image} alt="" className="card-img-top" />
                                                <div className="card-body d-flex flex-column">
                                                    <p>{item.company}</p>
                                                    <p className="lead text-dark">{item.title}</p>
                                                    <div className="mt-auto">
                                                        <a href={item.link} className="btn btn-info bg-gradient-info border-0 text-white">Read More</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>

                        </div>
                    </div>

                </div>
            </section>
            
            <section className="section py-6 overflow-hidden bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto text-center pt-5 pb-4">
                            <h2 className="display-6 text-dark">Happy Clients</h2>
                            <p className="lead">The list of clients we serve speaks for itself. We've represented everything from start-ups to big corporations with the same partnering approach and passion at the foundation of every engagement since our inception.</p>

                            <div className="text-center pb-5">
                                <small className="text-muted"><i className="mdi mdi-crown text-warning me-2"></i> <em className="opacity-50">Deployed more than a 100 screens</em></small>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-9 mx-auto">

                            <div className="row row-cols-2 row-cols-md-4 g-md-5 justify-content-center">
                                {
                                    logos.map((item, index) => (
                                        <div className="col mb-3" key={index}>
                                            <div className="card h-100 shadow-sm border-0 p-2">
                                                {item.is100? <i className="mdi mdi-crown text-warning position-absolute top-0 end-0"></i> : null}
                                                <img src={process.env.PUBLIC_URL+item.image} alt="" className="card-img-top" />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>

                        </div>
                    </div>

                </div>
            </section>

            <Testimonials />
            
            
            <section className="section py-6 overflow-hidden bg-light">
                <div className="container pb-6">
                    <div className="row">
                        <div className="col-lg-6 mx-auto text-center pt-5 pb-4">
                            <h2 className="display-6 text-dark">Image Gallery</h2>
                            <p className="lead">We are always keen on taking on the challenge of bringing our client's ideas to life. Here's a view of some of our past digital signage projects.</p>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-12 col-md-9 mx-auto">

                            <div className="row row-cols-1 row-cols-md-4 justify-content-center">
                                <Fancybox options={{ infinite: false }}>
                                    {
                                        gallery.map((item, index) => (
                                            <div className="col mb-3" key={index}>
                                                <div 
                                                    className="card gallery-card h-100 p-1" 
                                                    data-fancybox="gallery"
                                                    data-src={process.env.PUBLIC_URL + item.image}
                                                >
                                                    <img src={process.env.PUBLIC_URL + item.image} alt="" className="card-img-top" />
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Fancybox>
                            </div>

                        </div>
                    </div>
                    
                </div>
            </section>

            <CallAction wrapperClass="" />

        </>
    );
}
 
export default Portfolio;




const caseStudies = [
    {
        slug: 'adax',
        image: `${process.env.PUBLIC_URL}/blog/adax-300x169.jpg`,
        title: 'Spark by Screenplify debutes at ASEAN Data Analytics eXchange',
        company: 'ASEAN Data Exchange (ADAX)',
        link: `${process.env.PUBLIC_URL}/case-studies/CS-ADAX.pdf`
    },
    {
        slug: 'hatten',
        image: `${process.env.PUBLIC_URL}/blog/hatten-300x169.jpg`,
        title: 'Screenplify for Hospitality, Hatten Hotel Melaka replaces old solution with Screenplify',
        company: 'Hatten Hotel Melaka',
        link: `${process.env.PUBLIC_URL}/case-studies/CS-Hatten-Estadia.pdf`
    },
    {
        slug: 'uitm',
        image: `${process.env.PUBLIC_URL}/blog/uitm-300x169.jpg`,
        title: 'UiTM choose Screenplify for thier Interactive Directory Kiosk of the Faculty of Dentistry',
        company: 'UiTM Malasyia',
        link: `${process.env.PUBLIC_URL}/case-studies/CS-UiTM.pdf`
    },
    {
        slug: 'allainz',
        image: `${process.env.PUBLIC_URL}/blog/allianz-300x169.png`,
        title: 'Using Digital Signage for Internal Communications',
        company: 'Allianz Insurance',
        link: 'https://www.screenplify.com/blog/using-digital-signage-for-internal-communications'
    },
    {
        slug: 'sunway',
        image: `${process.env.PUBLIC_URL}/blog/sunway-main-300x169.jpg`,
        title: 'Smart Event Spaces as a Service',
        company: 'Sunway Resort and Hotels',
        link: 'https://www.screenplify.com/blog/event-spaces-as-a-services'
    },
    {
        slug: 'mydin',
        image: `${process.env.PUBLIC_URL}/blog/mydin_hypermarket-300x169.jpg`,
        title: 'MYDIN Deploys 100 Screens Nationwide',
        company: 'MYDIN Malaysia',
        link: `${process.env.PUBLIC_URL}/case-studies/CS-Mydin.pdf`
    },
]

const logos = [
    { image: `/logos/1-allianz.jpg`, is100: true },
    { image: `/logos/1-help.jpg`, is100: false },
    { image: `/logos/1-media_prima.jpg`, is100: false },
    { image: `/logos/1-mitsubishi.jpg`, is100: true },
    { image: `/logos/1-mydin.jpg`, is100: true },
    { image: `/logos/1-sony.jpg`, is100: false },
    { image: `/logos/1-sunway-group.jpg`, is100: true },
    { image: `/logos/1-sunway.jpg`, is100: true },
    { image: `/logos/ABC.jpg`, is100: false },
    { image: `/logos/adax.jpg`, is100: false },
    // {image:`/logos/altel.jpg`, is100: false},
    { image: `/logos/sunway_resort.jpg`, is100: true },
    { image: `/logos/sunway_pyramid.jpg`, is100: true },
    { image: `/logos/sunway_clio.jpg`, is100: true },
    { image: `/logos/sunway_bigbox.jpg`, is100: true },
    { image: `/logos/bcck.jpg`, is100: false },
    { image: `/logos/apu.jpg`, is100: false },
    { image: `/logos/ipc.jpg`, is100: false },
    { image: `/logos/mytown.jpg`, is100: false },
    { image: `/logos/kpj-ipoh.jpg`, is100: false },
    { image: `/logos/uitm.jpg`, is100: false },
    { image: `/logos/hilton-double-tree.jpg`, is100: false },
    { image: `/logos/estadia.jpg`, is100: false },
    { image: `/logos/hatten.jpg`, is100: false },
    { image: `/logos/CS_ Market.jpg`, is100: false },
    { image: `/logos/sirim.jpg`, is100: false },
    { image: `/logos/bromma.jpg`, is100: false },
    { image: `/logos/colas_rail.jpg`, is100: false },
    { image: `/logos/bv_2.jpg`, is100: false },
    { image: `/logos/bwave.jpg`, is100: false },
    { image: `/logos/chermain_poo.jpg`, is100: false },
    { image: `/logos/eko_holdings.jpg`, is100: false },
    { image: `/logos/excelko.jpg`, is100: false },
    // {image:`/logos/flavourist.jpg`, is100: false},
    { image: `/logos/focus_media.jpg`, is100: false },
    { image: `/logos/ges.jpg`, is100: false },
    { image: `/logos/homedec.jpg`, is100: false },
    { image: `/logos/ioi.jpg`, is100: false },
    { image: `/logos/krr.jpg`, is100: false },
    { image: `/logos/mahseng.jpg`, is100: false },
    { image: `/logos/sephora.jpg`, is100: false },
    { image: `/logos/macy.jpg`, is100: false },
    { image: `/logos/matta.jpg`, is100: false },
    { image: `/logos/tpm.jpg`, is100: false },
    { image: `/logos/tedxkl.jpg`, is100: false },
    { image: `/logos/magna.jpg`, is100: false },
]


const gallery = [
    {image: `/gallery/screenplify_apu.jpg`},
    {image: `/gallery/screenplify_brighton.jpg`},
    {image: `/gallery/screenplify_gsy.jpg`},
    {image: `/gallery/screenplify_hatten.jpg`},
    {image: `/gallery/screenplify_homedec.jpg`},
    {image: `/gallery/screenplify_ioiproperty.jpg`},
    {image: `/gallery/screenplify_ipc1.jpg`},
    {image: `/gallery/screenplify_ipc2.jpg`},
    {image: `/gallery/screenplify_klims.jpg`},
    {image: `/gallery/screenplify_lakecity.jpg`},
    {image: `/gallery/screenplify_mahsing.jpg`},
    {image: `/gallery/screenplify_mydin.jpg`},
    {image: `/gallery/screenplify_mydin2.jpg`},
    {image: `/gallery/screenplify_sephora1.jpg`},
    {image: `/gallery/screenplify_sephora2.jpg`},
    {image: `/gallery/screenplify_sirim.jpg`},
    {image: `/gallery/screenplify_spark.jpg`},
    {image: `/gallery/screenplify_spark2.jpg`},
    {image: `/gallery/screenplify_sunway.jpeg`},
    {image: `/gallery/screenplify_uitm.jpg`},
    {image: `/gallery/screenplify_uitm1.jpg`},
    {image: `/gallery/screenplify_uitm2.jpg`},
]