import React from 'react';
import {
    Switch,
    Route,
    useRouteMatch,
    Redirect,
    NavLink,
    // useParams,
    // NavLink
} from "react-router-dom";

import './index.scss';

import EULA from './EULA';
import Privacy from './Privacy';
import Warrenty from './Warrenty';
import Bribary from './Bribary';

const Legal = () => {
    const match = useRouteMatch();

    return (
        
        <section className="legal-main bg-light py-6">

            <div className="container pt-6">
                
                <div className="row">
                    <div className="col-12 col-md-3">
                        <ul className="nav flex-column bg-white p-3 rounded-3 position-sticky">
                            <li className="nav-item">
                                <NavLink to={`${match.path}/eula`} activeClassName="text-gradient text-primary" className="nav-link text-reset">EULA</NavLink> 
                            </li>
                            <li className="nav-item">
                                <NavLink to={`${match.path}/privacy`} activeClassName="text-gradient text-primary" className="nav-link text-reset">Privacy</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={`${match.path}/warranty`} activeClassName="text-gradient text-primary" className="nav-link text-reset">Warrenty & Returns</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={`${match.path}/anti-bribery`} activeClassName="text-gradient text-primary" className="nav-link text-reset">Anti-Bribary & Anti-Corruption Policy</NavLink>
                            </li>
                        </ul>
                    </div>

                    <div className="col-12 col-md-9">

                        <Switch>
                            <Route path={`${match.path}/eula`}>
                                <EULA />
                            </Route>
                            <Route path={`${match.path}/privacy`}>
                                <Privacy />
                            </Route>
                            <Route path={`${match.path}/warranty`}>
                                <Warrenty/>
                            </Route>
                            <Route path={`${match.path}/anti-bribery`}>
                                <Bribary/>
                            </Route>
                            <Route path={match.path}>
                                <Redirect to="legal/eula" />
                            </Route>
                        </Switch> 

                    </div>
                </div>

            </div>

        </section>
    );
}
 
export default Legal;