import React from 'react';

const PluginItem = ({name, text, image}) => {
    return (
        <div className="col-12 col-md-3 mb-4">
            <div className="card shadow-sm border-0 h-100 rounded-2">
                <img src={image.replace('/public', '')}alt="" className="card-image rounded-2" />
                <div className="card-body">
                    <h6 className="card-title text-dark text-uppercase text-center">{name}</h6>
                    <p className="m-0 text-center">{text}</p>
                </div>
            </div>
        </div>
    );
}
 
export default PluginItem;