import React from 'react'

const WaveUp = () => {
    return (
        <div className="position-absolute w-100 bottom-0">
    <svg width="100%" viewBox="0 -1 1920 166" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>wave-up</title>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(0.000000, 5.000000)" fill="#FFFFFF" fillRule="nonzero">
                <g id="wave-up" transform="translate(0.000000, -5.000000)">
                    <path d="M0,70 C298.666667,105.333333 618.666667,95 960,39 C1301.33333,-17 1621.33333,-11.3333333 1920,56 L1920,165 L0,165 L0,70 Z"></path>
                </g>
            </g>
        </g>
    </svg>
  </div>
    );
}
 
export default WaveUp;