import React from 'react'
import Masonry from 'react-masonry-css'
import { Link, useLocation } from 'react-router-dom';
import { ExternalReviews } from '../../Resources';
import Wreath from '../Awards/Wreath';

import './index.scss';

const reviews = [
    { name: 'Mark', pos: 'Marketing', place: 'Ikano Corporation Sdn Bhd', statemnt: 'Easy to access, navigate & use the platform from a client\'s perspective.The backend team is also responsive to attend to any issues & resolves them promptly.Highly recommend!'},
    { name:'Ong K.H.', pos: 'Group IT Director', place: 'Sunway Resorts & Hotels', statemnt: 'Dedicated and competent team. In house development team, hence solutions can be fixed without much hassle.'},
    { name:'Abdul Muhaimin Bin Jarni', pos: 'Manager, After Sales Planning - Service Dept', place: 'Mitsubishi Motors Malaysia Sdn Bhd', statemnt: 'Excellent service and pricing. Selected Screenplify as our company digital screen solution after weighing a few options from other services.'},
    { name:'Muhammad Shafiq Bin Kamil', pos: 'Head of IT Services', place: 'KPJ Ipoh Specialist Hospital', statemnt: 'Easy to use. Cloud control for the contents. Easy to integrated with hardware on local. Their support team also excellent and fast response upon help needed.'},
    { name:'Chan Kang Sheng', pos: 'IT Executive', place: 'DoubleTree by Hilton. Melaka', statemnt: 'Easy to use. Photo or video deploys immediately once upload. The device was functioning stable.'},
    { name:'Dr. Nik Mohd Mazuan', pos: 'Deputy Dean (Clinical)', place: 'UiTM', statemnt: 'Screenplify help improves information dissemination to our customers. It is user-friendly, better technology for the day-to-day operation. It provided robust content delivery, is mobile with nice graphics and sleek design. Great support from the company before and after purchase.'},
    { name:'Karen New', pos: '', place: 'Allianz Malaysia', statemnt: 'Screenplify is very convenient and so user - friendly to use. Best part is the price point and the support provided. I would really recommend this solution.'},
    
    { name: 'Eugene Chan', pos: 'IT Manager', place: 'Hatten Hotel Melaka', statemnt: 'With Screenplify, work became more streamlined, updating content is a breeze. Straight forward usage as its cloud based, easy to navigate through. Emphasis on the high availability and the excellent support.'},
    { name:'Vani Dhakshinamoorthy', pos: 'Program Director', place: 'Global Startup Youth', statemnt: 'The Global Startup Youth used the Screenplify kiosks to share important information about the event with the participants and mentors. The kiosks were a pleasure to use and the team was very efficient in making updates and changes to the kiosks in a timely manner. We see it as a more \'green\' solution at events and programs that facilitates intelligent information sharing inline with today\'s technology.'},
    { name:'Jason Ng', pos: '', place: 'TEDxKL', statemnt: 'Screenplify kiosks were exhibited throughout the TEDxKL event. It was synergistic to have the kiosks as directional guides, to display relevant information and even beautify the event area. Truly important use of space and I am very sure any event would find Screenplify kiosk a great addition.'},
    { name: 'Chermaine Poo', pos: '', place: 'Chermaine Poo\'s Cupcakes for Charity', statemnt: 'For a charity event I organised, I needed a method to convey a lot of information to the public quickly and easily. Screenplify kiosk was a very effective medium to do so for it harnessed the power of social media to keep my audience informed. The smooth navigation and ability to draw out contents from the kiosks makes them a must-have technology for events in this tech-hungry era.'},
]

const breakpointColumnsObj = {
    default: 3,
    992: 3,
    768: 2,
    576: 1
};

const checkPortfolio = (match, location) => {
    return location.pathname.indexOf('portfolio') > 0 ? true : false;
}

const Testimonials = ({ show = reviews.length}) => {
    const location = useLocation();

    return (
        <section className="section py-6 overflow-hidden">
            <div className="container">
                <div className="row pb-6">
                    <div className="col-lg-6 mx-auto text-center pb-4">
                        <h2 className="display-6 mb-5 text-dark">Don’t just take our word for it</h2>
                        <p className="lead">Listen to what our customers and clients have to say about us. Their valued comments, {checkPortfolio('', location) ? 'reviews and testimonials' : <Link className="text-reset highlight" to="/portfolio">reviews and testimonials</Link>} help to drive us forward.</p>
                    </div>
                </div>

                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >
                    {
                        reviews.slice(0, show).map((item, index) => (
                            <div className="col mb-4" key={index}>
                                <div className="card border-light text-center">
                                    <div className="card-body">
                                        <Stars />
                                        <p className="testimony-statement text-dark">"{item.statemnt}"</p>
                                        <p className="testimony-info">
                                            <span>{item.name}</span>
                                            <small>{item.pos}</small>
                                            <small>{item.place}</small>
                                        </p>
                                    </div>
                                    
                                </div>
                            </div>
                        ))
                    }
                </Masonry>

                {show === reviews.length ? <div className="row">
                    <div className="col-12 col-md-6 col-lg-3 mb-3">
                        <Wreath primary="ALL Rounder" secondary="5 Stars" />
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 mb-3">
                        <Wreath primary="Support" secondary="Awesome" />
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 mb-3">
                        <Wreath primary="USER FRIENDLY" secondary="Best Tech" />
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 mb-3">
                        <Wreath primary="CUSTOMIZABILITY" secondary="Best Tech" />
                    </div>
                </div> : undefined }

                <div className="row row-cols-1 row-cols-md-6 justify-content-center m-0 mt-lg-6">
                    <div className="col mb-4 mb-md-0">
                        <a className="d-block" href={ExternalReviews.productNation} target="_blank" rel="noreferrer">
                            <img src={`${process.env.PUBLIC_URL}/reviews/pnchoice.png`} className="img-fluid d-block mx-auto" style={{maxWidth: '100px'}} alt="" />
                        </a>
                    </div>
                    <div className="col mb-4 mb-md-0">
                        <a className="d-block" href={ExternalReviews.trustedMalaysia} target="_blank" rel="noreferrer">
                            <img src={`${process.env.PUBLIC_URL}/reviews/trustedmal-200x223.png`} className="img-fluid d-block mx-auto" style={{maxWidth: '100px'}} alt="" />
                        </a>
                    </div>

                    <div className="col">
                        <a className="d-block" href={ExternalReviews.funEmpire} target="_blank" rel="noreferrer">
                            <img src={`${process.env.PUBLIC_URL}/reviews/TFEround.png`} className="img-fluid d-block mx-auto" style={{maxWidth: '100px'}} alt="" />
                        </a>
                    </div>
                </div>

            </div>
        </section>
    );
}
 
export default Testimonials;


const Stars = () => {
    return (
        <div className="text-warning">
            <i className="mdi mdi-star"></i>
            <i className="mdi mdi-star"></i>
            <i className="mdi mdi-star"></i>
            <i className="mdi mdi-star"></i>
            <i className="mdi mdi-star"></i>
        </div>
    )
}