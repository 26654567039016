import React from 'react';
import { Link } from 'react-router-dom';

import './index.scss';
import '../Waves/Pattern.scss';

import { ReactComponent as FullLogo } from '../../assets/screenplify_logo.svg';
import { ResourceLinks, SocialLinks } from '../../Resources';
// import { version } from '../../../package.json';

const Footer = () => {
    return (
        <footer className="">
            <div className="py-6"> {/*bg-gradient-dark pattern diagonal-separator*/}

                <div className="container pt-6">
                    <div className="row">
                        
                        <div className="col-12 col-lg-4 mb-5 mb-lg-0 text-center text-md-start">
                            <div className="logo">
                                <FullLogo />
                            </div>
                            <p>Screenplify is a Enterprise-grade, Feature-packed software for digital signage and interactive touchscreens and kiosks. Buy software license for your digital displays or avail our bundle solutions, complete with the latest hardware technologies.</p>
                        </div>

                        <div className="col-lg-2 col-6 col-sm-4 ms-lg-auto mb-5 mb-lg-0">
                            <ul className="list-unstyled text-center text-md-start">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/software">Software</Link></li>
                                <li><Link to="/hardware">Hardware</Link></li>
                                <li><Link to="/portfolio">Portfolio</Link></li>
                                <li><Link to="/partners">Partners</Link></li>
                                <li><Link to="/contact">Contact Us</Link></li>
                            </ul>
                        </div>

                        <div className="col-lg-2 col-6 col-sm-4 mb-5 mb-lg-0">
                            <ul className="list-unstyled text-center text-md-start">
                                <li><Link to="/usecases">Use Cases</Link></li>
                                <li><Link to="/decision-guide">Decision Guide</Link></li>
                                <li><a href={ResourceLinks.blog}>Blog <i className="mdi mdi-call-made"></i></a></li>
                                <li><a href={ResourceLinks.plugin}>Plugins <i className="mdi mdi-call-made"></i></a></li>
                                <li><a href={ResourceLinks.templates}>Templates <i className="mdi mdi-call-made"></i></a></li>
                                <li><a href={SocialLinks.youtube}>YouTube Videos <i className="mdi mdi-call-made"></i></a></li>
                                {/* <li><a href={ResourceLinks.reviews}>Reviews <i className="mdi mdi-call-made"></i></a></li> */}
                            </ul>
                        </div>

                        <div className="col-lg-2 col-sm-4 mb-5 mb-lg-0">
                            <ul className="list-unstyled text-center text-md-start">
                                <li><a href="/legal/eula">EULA</a></li>
                                <li><a href="/legal/privacy">Privacy Policy</a></li>
                                <li><a href="/legal/warranty">Warrenty & Returns</a></li>
                                <li><a href="/legal/anti-bribery">Anti-Bribary & Anti-Corruption Policy</a></li>
                            </ul>
                        </div>

                    </div>
                </div>


                <div className="container">
                    <hr/>
                    <div className="row align-items-center justify-content-md-between pb-4">
                        <div className="col-md-6 text-center text-md-start">
                            <p className="m-0">&copy; 2016 - {new Date().getFullYear()} <a href="//www.ounch.com" rel="Screenplify Website">Ounch Sdn Bhd <small>(988628-w)</small></a> &bull; {process.env.REACT_APP_VERSION || '2.0.0d'}</p>
                        </div>
                        <div className="col-md-6">
                            <ul className="nav justify-content-center justify-content-md-end mt-3 mt-md-0">
                                <li className="nav-item">
                                    <a className="nav-link" href={SocialLinks.facebook} target="_blank" rel="noreferrer">
                                        <i className="mdi mdi-18px mdi-facebook"></i>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href={SocialLinks.instagram} target="_blank" rel="noreferrer">
                                        <i className="mdi mdi-18px mdi-instagram"></i>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href={SocialLinks.twitter} target="_blank" rel="noreferrer">
                                        <i className="mdi mdi-18px mdi-twitter"></i>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href={SocialLinks.youtube} target="_blank" rel="noreferrer">
                                        <i className="mdi mdi-18px mdi-youtube"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                </div>

            </div>
        </footer>
    );
}
 
export default Footer;