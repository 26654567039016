import React from 'react';

import useTitle from '../../hooks/useTitle';
import PageHeader from '../../components/PageHeader';
import ProductSpec from '../../components/ProductSpec';
import CallAction from '../../components/CallAction';


const Hardware = () => {
    useTitle('Supported Hardware');

    return (
        <>
            <PageHeader
                color="warning"
                title="State-of-the-Art Solutions"
                text="Screenplify works seamlessly on any screen regardless of their aspect ratio, orientation, or screen resolution. With Screenplify, you have the option of using your own screen of choice (installation is a breeze!) or to purchase the complete hardware/software bundle with the choice of the hardware we provide below."
                image="/use-cases/digital-signage-device-sunway.jpg"
            />

            <section className="section py-6 mt-md-5 bg-light">
                <div className="container px-md-6">


                    {/* <div className="card border-0 mb-4 mb-md-6">
                        <div className="card-body p-md-5">
                            <h3 className="text-dark">INTEL® Compute Stick</h3>
                            <p className="lead">Our best-selling digital signage player. It is conveniently micro sized at 4" long, which makes it easy to plugin to screens and keep away from view. It supports online and offline digital signage networks. Don't let its size deceive you though; powered by ScreenplifyOS makes it robust and reliable.</p>

                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <img src={`${process.env.PUBLIC_URL}/devices/digital_signage_hardware_intel_computestick_2.jpg`} className="img-fluid d-flex mx-auto" alt="digital signage hardware intel compute stick" />
                                </div>
                                <div className="col-12 col-lg-6 d-flex align-items-center">
                                    <ProductSpec
                                        cpu="Quad-core Intel® Atom"
                                        os="ScreenplifyOS"
                                        hdd="64 GB eMMC (soldered-down)"
                                        ram="2GB"
                                        gfx="Intel® HD Graphics 500, Dual HDMI * (1x HDMI * 2.0a, 1x HDMI * 1.4)"
                                        ports="1x USB 2.0, HDMI Port, Micro SD CArd Slot"
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}



                    <div className="card border-0 mb-4 mb-md-6">
                        <div className="card-body p-md-5">
                            <h3 className="text-dark">INTEL® NUC Rugged</h3>
                            <p className="lead">Meet the mighty, tiny mini PC only Intel® could build. It is a PC Kit small in size but big on toughness and reliability. Suitable for long-hours, small and rigged spaces.</p>

                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <img src={`${process.env.PUBLIC_URL}/devices/digital_signage_hardware_intel_nuc-rugged.jpg`} className="img-fluid d-flex mx-auto" alt="digital signage hardware intel nuc rugged" />
                                </div>
                                <div className="col-12 col-lg-6 d-flex align-items-center">
                                    <ProductSpec
                                        cpu="Intel® Celeron® Processor N3350"
                                        os="ScreenplifyOS"
                                        hdd="64 GB eMMC (soldered-down)"
                                        ram="4 GB RAM, dual - channel (soldered-down)"
                                        gfx="Intel® HD Graphics 500, Dual HDMI * (1x HDMI * 2.0a, 1x HDMI * 1.4)"
                                        ports="5x USB, Intel® i211 - AT 10 / 100 / 1000 Mbps"
                                    />
                                </div>
                            </div>
                        </div>
                    </div> 


                    <div className="card border-0 mb-4 mb-md-6">
                        <div className="card-body p-md-5">
                            <h3 className="text-dark">INTEL® NUC</h3>
                            <p className="lead">This mini media player is ideal for digital signage applications such as large-screen displays, interactive kiosks and menu boards. Supports visually stunning graphics and WiFi, all with low and efficient power consumption.</p>

                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <img src={`${process.env.PUBLIC_URL}/devices/digital_signage_hardware_intel_nuc.jpg`} className="img-fluid d-flex mx-auto" alt="digital signage hardware intel nuc" />
                                </div>
                                <div className="col-12 col-lg-6 d-flex align-items-center">
                                    <ProductSpec
                                        cpu="Intel® Core™ i5/i7"
                                        os="ScreenplifyOS"
                                        hdd="120 GB SSD (Extendible)"
                                        ram="4 GB (Extendible)"
                                        gfx="Intel® HD Graphics 500, Dual HDMI * (1x HDMI * 2.0a, 1x HDMI * 1.4)"
                                        ports="4x USB 3.0, Intel® i211 - AT 10 / 100 / 1000 Mbps"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="card border-0 mb-4 mb-md-6">
                        <div className="card-body p-md-5">
                            <h3 className="text-dark">x86 Generic Devices</h3>
                            <p className="lead">Free standing and mobile. Attract eye-balls from a far. Use it wherever and whenever.</p>

                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <img src={`${process.env.PUBLIC_URL}/devices/digital_signage_hardware_intel_kiosk.jpg`} className="img-fluid d-flex mx-auto" alt="digital signage hardware intel nuc rugged" />
                                </div>
                                <div className="col-12 col-lg-6 d-flex align-items-center">
                                    <ProductSpec
                                        cpu="Intel® Core™ i5/i7"
                                        os="ScreenplifyOS"
                                        hdd="120 GB SSD (Extendible)"
                                        ram="4 GB RAM (Extendible)"
                                        gfx="Intel® HD Graphics HDMI"
                                        ports="5x USB, Intel® i211 - AT 10 / 100 / 1000 Mbps"
                                    />
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-lg-6">
                                    <hr style={{opacity: .10}} />
                                    <ul className="list-unstyled">
                                        <li className="ps-4 mb-2"><i className="mdi mdi-check text-success me-2 ms-n4"></i>Available in 43", 49", 55" Screen Sizes</li>
                                        <li className="ps-4 mb-2"><i className="mdi mdi-check text-success me-2 ms-n4"></i>10 Points Capacitative Touch</li>
                                        <li className="ps-4 mb-2"><i className="mdi mdi-check text-success me-2 ms-n4"></i>With or Without Wheels</li>
                                        <li className="ps-4 mb-2"><i className="mdi mdi-check text-success me-2 ms-n4"></i>A-Grade Commercial Panel</li>
                                        <li className="ps-4 mb-2"><i className="mdi mdi-check text-success me-2 ms-n4"></i>1080 x 1920 Resolution</li>
                                        <li className="ps-4 mb-2"><i className="mdi mdi-check text-success me-2 ms-n4"></i>16 : 9 Aspect Ratio</li>
                                        <li className="ps-4 mb-2"><i className="mdi mdi-check text-success me-2 ms-n4"></i>400 nits Brightness</li>
                                        <li className="ps-4 mb-2"><i className="mdi mdi-check text-success me-2 ms-n4"></i>1200 : 1 Contrast Ratio</li>
                                        <li className="ps-4 mb-2"><i className="mdi mdi-check text-success me-2 ms-n4"></i>178/178 Viewing Angle</li>
                                        <li className="ps-4 mb-2"><i className="mdi mdi-check text-success me-2 ms-n4"></i>&gt;50,000 hrs Panel Lifetime</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>

            <CallAction />
        </>
    );
}
 
export default Hardware;