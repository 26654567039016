import React from 'react';

import useTitle from '../../hooks/useTitle';
import PageHeader from '../../components/PageHeader';
import ContactBar from '../../components/ContactBar';
import CallAction from '../../components/CallAction';


const Software = () => {
    useTitle('Software');

    return (
        <>
            <PageHeader
                color="primary"
                title="Screenplify"
                text="A Highly customizable, simplified solution that will power your digital displays. Fine-tune your screens in a matter of minutes and create a functional designs that you and your audience will love. It's time to bring your digital signage vision into reality."
                image="/use-cases/screenplify_welcome_card.jpg"
            />

            <section className="section py-6 mt-6">
                <div className="container py-4">
                    <div className="row">
                        <div className="col-lg-6 mx-auto text-center pt-5 pb-4">
                            <h2 className="display-6 text-dark">Simple, But Powerful!</h2>
                            <p className="lead">Screenplify offers a precisely designed set of features and tools to simplify digital signage management and deliver the best user experience.</p>
                            <p className="lead mb-5">Screenplify is the most advanced digital signage solution in the market. Our features are too many to count and list, here are a few;</p>
                        </div>
                    </div>
                    
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 justify-content-center">

                        {
                            features.map((item, index) => (
                                <div className="col" key={index}>
                                    <div className={`card mb-3 border-0 ${index%2 === 0 ? '' : ''}`}>
                                        <div className="card-body text-center">
                                            <div className="icon-shape text-white bg-gradient-warning rounded-pill mb-3 a-style mx-auto">
                                                <i className={`mdi ${item.icon} mdi-24px`}></i>
                                            </div>
                                            <h4 className="text-dark">{item.title}</h4>
                                            <p>{item.text}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                </div>
            </section>

            <ContactBar />

            <section className="section py-6">
                <div className="container py-4">
                    <div className="row">
                        <div className="col-lg-6 mx-auto text-center pt-5 pb-4">
                            <h2 className="display-6 text-dark">Enterprise Grade</h2>
                            <p className="lead mb-4">Screenplify is an Enterprise-Grade software at it's core. Screenplify offers a number of features to cater and satisfy all the needs of an enterprise digital signage solution.</p>
                        </div>
                    </div>

                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 justify-content-center">

                        {
                            enterprise.map((item, index) => (
                                <div className="col" key={index}>
                                    <div className="card mb-3 border-0">
                                        <div className="card-body text-center">
                                            <div className="icon-shape text-white bg-gradient-success rounded-pill mb-3 a-style mx-auto">
                                                <i className={`mdi ${item.icon} mdi-24px`}></i>
                                            </div>
                                            <h5 className="text-dark">{item.title}</h5>
                                            <p>{item.text}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>

            <CallAction />
        </>
    );
}

export default Software;

const enterprise = [
    {
        icon: 'mdi-cloud-braces',
        title: 'Hosting Options',
        text: 'Enterprises have specific needs for performance, security, data sovereignty, reliability and geo-fencing. Screenplify offers isolation from the public internet as well as data centers across the globe running on trusted servers and infrastructure. '
    },
    {
        icon: 'mdi-tune-vertical',
        title: 'Visibility & Control',
        text: 'Screenplify offers highest level of capabilities and confidence when it comes to our of the players. We provide \'Super Admin\' level access to the players for for maximum control and visibility.'
    },
    {
        icon: 'mdi-timer',
        title: 'Stability & Availability',
        text: 'Enterprise softwares help to ensure smooth operation and business continuity. Screenplify players are desined to operate 24/7. Screenplify has built-in fail-safes for content update and fast recovery. Screenplify\'s hosted service can guarantee 99% uptime so customers can securely access, manage and monitor their devices from anywhere. '
    },
    {
        icon: 'mdi-shield-lock',
        title: 'Security',
        text: 'End-to-end encryption, certificate-based communication, player USB port locking, and only syncing data that is needed are some of the measures that can help reduce risks and ensure that Screenplify does not pose a threat to your business continuity.'
    },
    {
        icon: 'mdi-code-braces-box',
        title: 'Continuous Development',
        text: 'Active development and maintenance of any system ensures integrity that the system runs smoothly and is on the latest software technology. Regular updates also give you access to new features and new functionality to help better streamline and optimise your digital signage strategy.'
    },
    {
        icon: 'mdi-cog-transfer',
        title: 'Seamless Integration & Automation',
        text: 'Customers can optimise ROI and improve business processes with Screenplify\'s built-in API for seamless integration, resulting in increased efficiency and significant cost savings.Screenplify also has a number of extendible plugins that help with data linking and automating repetitive and time-consuming tasks.'
    },
    {
        icon: 'mdi-chart-bubble',
        title: 'Scalability',
        text: 'Critical for business growth, any Enterprise software must keep up with the expanding business. Screenplify is designed to effortlessly accommodate any and all increase/changes in workflow, number of screens, data, workload and users.'
    },
    {
        icon: 'mdi-chart-bar-stacked',
        title: 'Analytics and Reporting',
        text: 'Screenplify provides appropriate analytics and reporting tools to assist executives in analyzing and understanding process efficiencies and making data-driven decisions that can improve organizational performance.'
    },
    {
        icon: 'mdi-account-group',
        title: 'Multi-Tenanted, Flexi-Permissions User Management',
        text: 'Critical for business growth, any Enterprise software must keep up with the expanding business. Screenplify is designed to effortlessly accommodate any and all increase/changes in workflow, number of screens, data, workload and users.'
    },
    {
        icon: 'mdi-family-tree',
        title: 'Multi-Threaded Data Sync',
        text: 'Screenplify uses a multi-threaded, multi-process architecture for syncing data to devices allowing for faster, concurrent data transfer and for better optimized usage of storage and network resources.'
    },
]

const features = [
    {
        icon: 'mdi-folder-multiple-image',
        title: 'Multi-media Support',
        text: 'Supports an extensive list of file extensions to display images, videos, maps, and directories. Also supports RSS feeds (both online and offline).'
    },
    {
        icon: 'mdi-timer',
        title: 'Scheduled Content',
        text: 'Multi-level scheduling of assets and pages allows for pre-planning and having your screen content prepared takes the pressure out of managing your displays.'
    },
    {
        icon: 'mdi-monitor-edit',
        title: 'Powerful Editor',
        text: 'Resize, reposition, and configure images, messages and other media to your heart\'s desire with our brilliant layout editor.'
    },
    {
        icon: 'mdi-monitor-eye',
        title: 'Device Manager',
        text: 'It allows users to view, control and manage the devices in their digital signage network in real-time. '
    },
    {
        icon: 'mdi-phone-rotate-portrait',
        title: 'Display Flexibility',
        text: 'Set your display dimensions and Screenplify seamlessly works with any screen resolution and aspect ratio.'
    },
    {
        icon: 'mdi-power-plug',
        title: 'Plugin Extendible',
        text: 'Our extensible platform allows for integration with custom-made plugins and modules.'
    },
    {
        icon: 'mdi-chart-bar',
        title: 'Reporting & Analytics',
        text: 'Assets reports, usage monitoring, uptime charts; we allow for custom reporting tailored to the your needs.'
    },
    {
        icon: 'mdi-api',
        title: 'API Integration',
        text: 'Our built-in API enables developers to integrate the digital signage platform into their existing applications and services.'
    },
    {
        icon: 'mdi-account-group',
        title: 'Multi-level User Permissions',
        text: 'Multi-level user permissions allow you to group and segment your users, ensuring that they only have access to the features they need to complete the tasks assigned to them.'
    },
    {
        icon: 'mdi-eye-check',
        title: 'Preventive Monitoring',
        text: 'Preventive Monitoring allows you to receive notifications about your devices via email when you’re not logged into your Screenplify dashboard.'
    },
    {
        icon: 'mdi-account-supervisor',
        title: 'Approval System',
        text: 'Coupled with multi-level user permissions; allows you to create user groups that can accept or request for content management and publishing to devices.'
    },
    {
        icon: 'mdi-cast-off',
        title: 'Offline Playback',
        text: 'After you have designed and published your content, it is stored on the device. Means even if your connection drops, the device will continue to play.'
    },
    {
        icon: 'mdi-usb-flash-drive',
        title: 'Offline Publish',
        text: 'In addition to Online publish, our also system allows to publish content to the players even if you have trouble connecting them to the server. All you need is a thumb-drive.'
    },
    {
        icon: 'mdi-comment-alert',
        title: 'Broadcast',
        text: 'Our broadcast features delivers all kinds of instant customized messages, including evacuation and emergency alerts.'
    },
    {
        icon: 'mdi-handshake',
        title: 'Support',
        text: 'Our experienced and certified local team is always there to help you 24/7 via email and chat and phone lines are open during normal business hours.'
    },
    {
        icon: 'mdi-account-heart',
        title: 'Training',
        text: 'We provide free personalized training once for all our customers. Trainings can also be procured as and when needed. We offer both online and on-site options.'
    },
]