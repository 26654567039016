import React from 'react';

const EULA = () => {
    return (
        <div>
            <div className="card border-0 shadow-lg">
                <div className="card-header bg-gradient-primary p-5">
                    <h2 className="text-white display-6">End User License Agreement</h2>
                    <p className="text-white lead">Last Updated: 01 March, 2020</p>
                </div>

                <div className="card-body p-5">

                    <p className="lead">BY DOWNLOADING, INSTALLING, COPYING, ACCESSING OR USING THIS SOFTWARE, YOU AGREE TO THE TERMS OF THIS AGREEMENT. IF YOU ARE ACCEPTING THESE TERMS ON BEHALF OF ANOTHER PERSON OR COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT AND WARRANT THAT YOU HAVE FULL AUTHORITY TO BIND THAT PERSON, COMPANY OR LEGAL ENTITY TO THESE TERMS.</p>

                    <p className="lead">IF YOU DO NOT AGREE TO THESE TERMS: DO NOT INSTALL, COPY, ACCESS OR USE THE SOFTWARE, AND RETURN THE SOFTWARE AND PROOF OF ENTITLEMENT TO THE PARTY FROM WHOM YOU ACQUIRED THEM.</p>

                    <p>In this End User License Agreement (the “Agreement”), capitalized words and terms have specific meanings that are defined throughout the body of the Agreement. This Agreement is between you ("You" or the “Customer”) and Ounch Sdn Bhd that is contracting with You where You have purchased the license to use the Software as indicated in the purchase order ("Ounch").</p>

                    <p>“Software” means each Ounch software program in object code format licensed by Ounch, including any modifications, as indicated in the purchase order.</p>

                    <ol>

                        <li>
                            <h5>LICENSE GRANT</h5>
                            <p>Subject to the terms and conditions of this Agreement, Ounch hereby grants to You a non-exclusive, non-transferable right to use the Software (for the purpose of this Agreement, use of the Software means to access, install, download, copy or otherwise benefit from using the Software during the license term according to the license type agreed in the purchase order) solely for your own internal operations. The Software is owned by Ounch and/or its licensors and are copyrighted and licensed, NOT SOLD.</p>
                        </li>

                        <li>
                            <h5>YOUR OBLIGATIONS</h5>
                            <p>In using the Software, or any part of it, You shall:</p>
                            
                            <ol>
                                <li>ensure that the Software is installed on designated equipment(s) only;</li>
                                <li>keep a complete and accurate record of the use of the Software and produce such record to the Supplier on request from time to time;</li>
                                <li>notify Ounch as soon as You become aware of any unauthorized use of the Software by any person;</li>
                                <li>permit Ounch to inspect and have access to any premises (and to the computer equipment located there) at or on which the Software is being kept or used, and have access to any records kept in connection with this license, for the purposes of ensuring that You are complying with the terms of this license. Ounch will provide reasonable advance notice to You of such inspections, which shall take place at reasonable times. </li>
                                <li>NOT use or access the Software (i) if You are or become a direct competitor of Ounch, except with Ounch’s prior written consent, or (ii) for purposes of competitive benchmarking or similar purposes;</li>
                                <li>NOT be entitled to license, sublicense, sell, resell, transfer, assign, distribute, rent, lease, or otherwise commercially exploit the Software in any way;</li>
                                <li>to the maximum extent such restriction is permitted by applicable law, NOT modify, decompile, reverse assemble, reverse engineer, translate or disassemble, or make derivative works based on, any part of the Software for any reason or purpose;</li>
                                <li>at all times comply with all applicable data protection laws and NOT use the Software in contravention with such laws. You will indemnify Ounch against any claim brought against Ounch in respect of any such contravention by You.</li>
                            </ol>

                        </li>

                        <li>
                            <h5>Support and training</h5>
                            Ounch’s policy for providing support in relation to the Software shall be available at www.screenplify.com. As part of the Software, Ounch will provide the Customer with its standard customer support services during the normal business hours in accordance with Ounch’s Support Services Policy in effect at the time of the services at no additional cost to the Customer. Ounch may amend the Support Services Policy in its sole and absolute discretion from time to time. You may purchase enhanced support services separately at Ounch’s then current rates and applicable terms.
                        </li>

                        <li>
                            <h5>Modifications</h5>
                            Ounch shall have the right to update, to provide new functionality or otherwise change the design of any Software or to discontinue the manufacture or sale of any Software in its absolute discretion without any liability to You.
                        </li>

                        <li>
                            <h5>Intellectual Property Rights</h5>
                            You acknowledge that all intellectual property rights in the Software and any related services belong and shall belong to Ounch or the relevant third-party owners (as the case may be), and the Customer shall have no rights in the Software other than the right to use it in accordance with the terms of this license (and/or any related third party license). “Ounch” is a registered trademark of Ounch Sdn Bhd. Other Ounch related logos, product names, and service names are also trademarks of Ounch Sdn Bhd.
                        </li>

                        <li>
                            <h5>Third Party Software</h5>
                            The Software may contain freely available and distributable and/or open source software and other copyrighted material by third parties (“Third Party Software”). The Third Party Software shall be governed by their respective license terms and conditions instead of the above license terms which are applicable to the Software. Ounch shall have no warranty or indemnification obligations with respect to any Third Party Software. Your warranty and indemnification rights, if any, with respect to Third Party Software shall be according to such third party’s applicable terms and conditions. THIRD PARTY SOFTWARE IS PROVIDED WITHOUT ANY CONDITION OR WARRANTY WHATSOEVER UNLESS OTHERWISE SPECIFIED IN THE RESPECTIVE THIRD PARTY SOFTWARE LICENSE TERMS AND CONDITIONS. OUNCH EXPRESSLY DISCLAIMS ANY CONDITION, WARRANTY OR LIABILITY IN RESPECT OF THE SOFTWARE WHICH IS CAUSED BY OR RELATE TO THIRD PARTY SOFTWARE.
                        </li>

                        <li>
                            <h5>Confidentiality</h5>

                            <ol>
                                <li>
                                    “Confidential Information” means all information (whether written, oral or in electronic form) concerning the business and affairs of either party that the other party obtains or receives as a result of the discussions leading up to or the entering into or the performance of this Agreement.
                                </li>

                                <li>
                                    A party receiving Confidential Information (the “Recipient”) shall keep in strict confidence all such Confidential Information of the other party (the “Discloser”). Both Parties agree in relation to the Confidential Information belonging to the other Party that during this Agreement and for five (5) years afterwards they shall:
                                    <ol>
                                        <li>keep such information confidential and shall not disclose it to any third party; and</li>
                                        <li>use such information only in so far as is necessary to perform this Agreement.</li>
                                    </ol>
                                </li>

                                <li>
                                    The Recipient shall be responsible for any unauthorized disclosure or use of the Discloser’s Confidential Information made by any of its employees, officers, agents, representatives or sub-contractors and shall take all reasonable precautions to prevent such unauthorized disclosure or use.
                                </li>

                                <li>
                                    The above restriction as to disclosure and use shall not apply to Confidential Information which:
                                    <ol>
                                        <li>the Recipient can demonstrate by documentary evidence has been in its possession prior to disclosure by the other party and not subject to any other obligations as to confidentiality;</li>
                                        <li>is required to be disclosed by law, regulation or pursuant to an order of a competent authority, or</li>
                                        <li>at the time of receipt by the receiving party, is in the public domain.</li>
                                    </ol>
                                </li>

                            </ol>
                        </li>

                        <li>
                            <h5>Data</h5>
                            The Customer and users shall ensure, and be responsible for, the accuracy of any data, information, submissions, or records provided by them or their authorized users to Ounch. The Customer or users warrants that any data, information, submissions, and records provided to Ounch by it or its authorized users is to its knowledge accurate and contains no known or suspected material inaccuracy, distortion, or manipulation. The Customer or user shall have secured, and is responsible for obtaining and maintaining, the legal right to provide Ounch such data, information, submissions, and records that are in the Customer or user’s control, including data, information, submissions, and records it delivers to Ounch that are subject to an interest of a third party. The Customer or user also warrants that it has the right to convey such data, information, submissions, and records delivered to Ounch and is under no conflicting obligation not to do so. The Customer or user is responsible for promptly updating any change in such data, information, submissions or records. Ounch shall not disclose or distribute any information in a manner that identifies a customer or user. Ounch may (but is not obliged to) monitor all data, records, information, or submissions made through the site. Ounch may use and publish such data, records, information, or submissions in an aggregated and/or anonymized manner, so long as it does not specifically incorporate or disclose any customer data, or customer or user identity.
                        </li>

                        <li>
                            <h5>Your Indemnifications of Ounch</h5>
                            PLEASE NOTE THAT YOUR USE OF THE SOFTWARE AND RELATED SERVICES WILL RESULT IN THE ERASURE OF ALL (OR SPECIFIED) DATA AND FILES IN YOUR HARD DRIVE, COMPUTER SYSTEM, STORAGE OR MOBILE DEVICE AND THAT YOU SHALL HAVE SOLE AND EXCLUSIVE RESPONSIBILITY FOR BACKING-UP YOUR DATA OR THIRD PARTY DATA UNDER YOUR CONTROL IN YOUR HARD DRIVE, SYSTEM, STORAGE OR DEVICE. OUNCH SHALL NOT BE RESPONSIBLE FOR ANY LOSS OF DATA. You hereby agree to indemnify and save harmless the Ounch and authorized resellers (“Indemnified Parties”) from and against all claims and losses in any way incurred by any Indemnified Parties in respect of any proceedings to which the Indemnified Party is made a party in connection with or arising out of (i) your use of the Software or loss of data (ii) as a result of your actions, misuse of the Software, non-compliance with the terms herein or failure to operate the Software in accordance with the documentation; (iii) in connection with or arising out of your use of the Software in violation of any applicable laws;
                        </li>

                        <li>
                            <h5>Ounch’s Indemnification of You</h5>
                            Ounch warrants that, except for Third Party Software, any part of the Software shall not, when used by You in accordance with this Agreement, infringe any intellectual property rights of a third party in the country of delivery. Ounch may, at its option, either defend or settle any claim made against You by a third party alleging that the Software, except Third Party Software, infringes a right of a third party, or Ounch may pay the costs and damages finally awarded against You by a competent court or an out-of-court settlement; But only upon these conditions that (i) You will notify Ounch within thirty (30) days of receipt of any third party claim; (ii) Ounch will be granted the exclusive right to arrange any defense or settlement; and (iii) You will not make any statement contradictory to the interests of Ounch in connection with such claim.
                        </li>

                        <li>
                            <h5>Limitation of Liability</h5>
                            <ol>
                                <li>IN ANY EVENT, OUNCH OR ITS AUTHORIZED DISTRIBUTORS SHALL NOT HAVE ANY LIABILITY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES (INCLUDING ANY LOSS OF PROFITS, REVENUE OR DATA, BUSINESS INTERRUPTION, LOSS RESULTING FROM SUBSTITUTE PURCHASE OF GOODS, OR OTHER SIMILAR LOSS) ARISING OUT OF THE USE OR INABILITY TO USE THE SOFTWARE, EVEN IF OUNCH OR ITS AUTHORISED DISTRIBUTORS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</li>
                                <li>IN ANY EVENT, THE AGGREGATE LIABILITY (WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR OTHER THEORY) OF OUNCH AND ITS AUTHORIZED DISTRIBUTORS ARISING OUT OF THE USE OR INABILITY TO USE THE SOFTWARE SHALL NOT EXCEED THE LICENSE FEES PAID BY YOU FOR THE SOFTWARE WHICH GAVE CAUSE TO THE CLAIM.</li>
                                <li>THESE LIMITATIONS AND EXCLUSIONS SHALL NOT APPLY TO ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED BY LAW.</li>
                            </ol>
                        </li>

                        <li>
                            <h5>Termination</h5>
                            Without prejudice to your payment obligations, You may terminate your licence at any time. Ounch may terminate your licence immediately in the event that You materially breach the terms of this Agreement. Upon such termination, You shall promptly return all copies of the Software, documentation, and Hardware.
                        </li>

                        <li>
                            <h5>Miscellaneous</h5>
                            <ol>
                                <li>
                                    <h5>Severability</h5>
                                    If any term or other provision of this Agreement is invalid, illegal or incapable or being enforced by any rule of law or public policy, all other terms and provisions of this Agreement shall still remain in full force and effect as long as its economic and legal intentions are not adversely affecting any party in any manner.
                                </li>

                                <li>
                                    <h5>Waiver</h5>
                                    A waiver of any right under this agreement is only effective if it is in writing and such waiver should apply only to the party to whom it is addressed and for such situations.
                                </li>

                                <li>
                                    <h5>Force Majeure</h5>
                                    Ounch shall have no liability to You under this Agreement if it is prevented from or delayed in performing its obligations under this agreement, or from carrying on its business, by acts, events, omissions or accidents beyond its reasonable control, which also include strikes, lock-outs or other industrial disputes (whether involving the workforce of Ounch or any other party), failure of a utility service or transport or telecommunications network, act of God, war, riot, civil commotion, malicious damage, compliance with any law or governmental order, rule, regulation or direction, accident, breakdown of plant or machinery, fire, flood, storm or default of suppliers or sub-contractors. Ounch shall notify the Customer of such an event and its expected duration.
                                </li>

                                <li>
                                    <h5>No partnership or Agency</h5>
                                    Nothing in this Agreement is intending to create a partnership between the parties, or authorize either party to act as agent for the other. Neither party shall have the authority to act in the name or on behalf of or otherwise to bind the other in any way (which may include the making of any representation or warranty, the assumption of any obligation or liability and the exercise of any right or power).
                                </li>

                                <li>
                                    <h5>Notice</h5>
                                    Notices in connection with this Agreement by either party will be in writing and will be sent by electronic mail, postal service, or a delivery service. You may not provide notice to Ounch of an Ounch breach or provide notice of termination of this Agreement by electronic mail. Notices from Ounch to You will be effective (a) in the case of notices by email, one (1) day after sending to the email address provided to Ounch, or (b) in the case of notices by mail or delivery service, five (5) days after sending by regular post or delivery service to the address provided to Ounch. You hereby consent to service of process being effected on You by registered mail sent to the address mentioned on the Order Form. Notices from You to Ounch will be effective (a) in the case of notices by email, one (1) day after sending to (and receipt by Ounch at) the email addresses stated in the Order Form, or (b) in the case of notices by mail or delivery service, when received by Ounch at the address stated in the Order Form.
                                </li>

                                <li>
                                    <h5>Entire Agreement</h5>
                                    This Agreement specifies the entire agreement between You and Ounch relating to the subject matter hereof and it supersedes all prior or contemporaneous oral or written communications, proposals and representations with respect to the Software or any other subject matter covered herein. Nothing contained in any purchase order submitted by a party other than order dates, identity, location, quantity and price shall in any way serve to modify or add to the terms of this Agreement.
                                </li>
                            </ol>
                        </li>

                        <li>
                            <h5>Governing Law and Jurisdiction</h5>
                            By using Ounch, you agree that such use, as well as this End User License Agreement shall be governed by and construed in accordance with the laws of Malaysia and you agree to submit to the exclusive jurisdiction of the Malaysian courts.
                        </li>

                        <li>
                            <h5>Changes to EULA</h5>
                            We may update End-User License Agreement from time to time. When we do that, we increase the EULA version and update its release date. Your continued use of our Service following any such modification constitutes your acceptance of these modified EULA.
                        </li>

                    </ol>

                </div>
            </div>
        </div>
    );
}
 
export default EULA;