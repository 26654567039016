import React, { useEffect, useRef } from 'react';

import './index.scss';
import WaveLines from '../Waves/Lines';

import { ReactComponent as TextSVG} from './assets/text.svg';
import { ReactComponent as MapMarker} from './assets/map-markers.svg';
import { ReactComponent as Sun} from './assets/sun.svg';
import Notes from '../MusicalNotes';
import Clock from '../AnalogClock';
import Snowy from '../WeatherIcon/Snowy';

const PluginHeader = () => {
    const parentNode = useRef(null);
    const canvasNode = useRef(null);
    
    useEffect(() => {

        window.addEventListener("resize", handleScale);

        handleScale();

        return () => {
            window.removeEventListener("resize", handleScale);
        }

    }, []);
    
    const handleScale = () => {
        const parentWidth = parentNode.current.offsetWidth - 30; // 30 for padding
        const canvasWidth = canvasNode.current.offsetWidth;
        const canvasHeight = canvasNode.current.offsetHeight;
        const scale = ((parentWidth * 100) / canvasWidth);
        const height = (canvasHeight * scale) / 100;

        parentNode.current.style.height = `${height}px`;
        canvasNode.current.style.transform = `scale(${scale / 100})`;
    }

    return (
        <section className="section py-6 position-relative overflow-hidden">
            <WaveLines dark={true} opacity="opacity-25" />

            <div className="container pt-6 position-relative">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-6 d-flex align-items-center">

                        <div className="p-4 my-sm-0 my-sm-6 mt-8 mb-5">
                            <h2 className={`display-6 text-dark text-gradient text-primary`}>Our Plugins</h2>
                            <p className="lead pe-0 pe-lg-5">These are the tools we have built to further enhance your digital signage experience. Screenplify and the plugins work perfectly together to provide advanced additional features.</p>
                        </div>

                    </div>

                    <div className="col-12 col-lg-6" ref={parentNode}>

                        <div className="plugin-hero-animation" ref={canvasNode}>
                            <div className="bubbles heart"></div>
                            <div className="bubbles heart"></div>

                            <div className="bubbles like"></div>
                            <div className="bubbles like"></div>

                            <div className="bubbles facebook"></div>
                            <div className="bubbles instagram"></div>
                            <div className="bubbles twitter"></div>

                            <div className="bursting-heart"></div>
                            <div className="twitter-share"></div>
                            <div className="facebook-like"></div>

                            <Notes />

                            <div className="text-svg">
                                <TextSVG />
                            </div>

                            <div className="analog-clock">
                                <Clock />
                            </div>

                            <div className="map-marker">
                                <MapMarker />
                            </div>

                            <div className="weather-icon">
                                <Sun />
                                <Snowy />
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </section>
    );
}
 
export default PluginHeader;