import React from 'react';
import { Link } from 'react-router-dom';

import './index.scss';

const Logos = [
    {slug: 'allainz', image: `${process.env.PUBLIC_URL}/logos/1-allianz.jpg`},
    {slug: 'mydin', image: `${process.env.PUBLIC_URL}/logos/1-mydin.jpg`},
    {slug: 'sunway', image: `${process.env.PUBLIC_URL}/logos/1-sunway-group.jpg`},
    { slug: 'mitsubishi', image: `${process.env.PUBLIC_URL}/logos/1-mitsubishi.jpg`},
    {slug: 'mytown shoppig center', image: `${process.env.PUBLIC_URL}/logos/mytown.jpg`},
    {slug: 'help university', image: `${process.env.PUBLIC_URL}/logos/1-help.jpg`},
    // {slug: 'sony', image: `${process.env.PUBLIC_URL}/logos/1-sony.jpg`},
    // {slug: 'kpj', image: `${process.env.PUBLIC_URL}/logos/kpj-ipoh.jpg`},
    // {slug: 'uitm', image: `${process.env.PUBLIC_URL}/logos/uitm.jpg`},
]

const Companies = () => {
    return (
        <section className="section py-6 overflow-hidden">
            <div className="container text-center pt-6">
                <div className="row">
                    <div className="col-lg-6 mx-auto text-center pb-4">
                        <h2 className="display-6 mb-5 text-dark">Trusted by World's Leading Companies</h2>
                        <p className="lead">Screenplify has quickly become well-known as a trusted partner of many of the regions key public and private organizations. Check out some of <Link to="/portfolio" className="text-reset highlight">our work</Link>, and see for yourself why we’ve been <Link to="/portfolio" className="text-reset highlight">trusted by so many companies</Link> for their digital signage needs.</p>
                    </div>
                </div>

                <div className="row row-cols-3 row-cols-md-4 row-cols-lg-6 gx-5 pt-5 justify-content-center">
                    { Logos.map((item, index) => (
                        <div className="col mb-3" key={index}>
                            <img src={item.image} alt={item.slug} className="img-fluid company-logo" />
                        </div>
                    ))}
                    {/* <div className="col mb-3 bg-light rounded-2 d-flex align-items-center justify-content-center">
                        <Link to="/portfolio" className="text-gradient text-primary">See Our Portfolio</Link>
                    </div> */}
                </div>

            </div>
        </section>
    );
}
 
export default Companies;