import React, { useReducer } from 'react';
import CallAction from '../../components/CallAction';
import Spinner from '../../components/Spinner/Spinner';
import WaveLines from '../../components/Waves/Lines';
import getUID from '../../utils/GenerateUID';
import validateEmail from '../../utils/ValidateEmail';

const coverImageStyles = { 
    backgroundImage: `url(${process.env.PUBLIC_URL}/pexels/pexels-photo-789822.jpeg)`,
    backgroundSize: 'cover',
    borderRadius: '0 0 0 200px'
}

const URL = `${process.env.PUBLIC_URL}/api/contact_us.php`;

const Contact = () => {

    const [state, setState] = useReducer((state, newState) => ({
        ...state,
        ...newState
    }), {
        isLoading: false,
        isSuccess: false,
        isError: false,
        fullname: '',
        email: '',
        message: '',
        reference: getUID()
    });

    const onMessage = () => {
        setState({
            isLoading: false,
            isSuccess: false,
            isError: false,
        });

        if (
            state.fullname.trim() === '' || 
            state.email.trim() === '' || 
            state.message.trim() === '' || 
            validateEmail(state.email.trim()) === false 
        ){
            setState({
                isLoading: false,
                isSuccess: false,
                isError: true,
            });

            return false;
        }

        setState({
            isLoading: true,
        });

        const { isLoading, isSuccess, isError, ...data } = state;

        console.log(data)

        fetch(URL, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                setState({
                    isLoading: false,
                    isSuccess: true,
                    isError: false,
                });
            })
            .catch((error) => {
                console.error('Error:', error.toString());
                setState({
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                });
            });

        return false;
    }

    return (
        <>
            <section className="section py-6 position-relative overflow-hidden">

                <div>
                    <div className="position-absolute fixed-top ms-auto w-50 h-100 background-mask background-mask-info z-index-0 d-none d-sm-none d-md-block" style={coverImageStyles}></div>
                </div>

                <WaveLines dark={true} opacity="opacity-25" />

                <div className="container pt-6">
                    <div className="row">
                        <div className="col-12 col-md-7 d-flex justify-content-center flex-column">

                            <div className="card border-0 d-flex blur justify-content-center p-4 shadow-primary my-sm-0 my-sm-6 mt-8 mb-5">
                                <div className="text-center">
                                    <h2 className="display-6 text-gradient text-primary">Contact Us</h2>
                                    <p className="lead">For a demo or questions, including partnership opportunities, please email info@screenplify.com or contact using our contact form.</p>
                                </div>

                                {state.isLoading === false && state.isSuccess === false ? <form noValidate className={state.isError ? 'was-validated' : ''}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col mb-3">
                                                <label htmlFor="name" className="form-label text-dark">Full Name</label>
                                                <input type="text" className="form-control rounded-3" id="name" placeholder="Full Name" required onChange={(e) => setState({ fullname: e.target.value })} />
                                                <div className="invalid-feedback">Full name is required.</div>
                                            </div>
                                            <div className="col mb-3">
                                                <label htmlFor="email" className="form-label text-dark">Email</label>
                                                <input type="email" className="form-control rounded-3" id="email" placeholder="me@example.com" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" onChange={(e) => setState({ email: e.target.value })} />
                                                <div className="invalid-feedback">Valid email is required.</div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="description" className="form-label text-dark">How can we help you?</label>
                                            <textarea className="form-control rounded-3" id="description" rows="3" placeholder="Describe your query in at least 250 characters" required onChange={(e) => setState({ message: e.target.value })}></textarea>
                                            <div className="invalid-feedback">Please type in a message.</div>
                                        </div>
                                        <div className="text-center">
                                            <button className="btn btn-primary border-0 bg-gradient-primary px-4 py-3 btn-lg" type="button" onClick={ onMessage }>Send Message</button>
                                        </div>
                                    </div>
                                </form> : null}

                                {state.isLoading === true ? <div className="my-5 py-6">
                                    <Spinner />
                                </div> : null}

                                {state.isLoading === false && state.isSuccess === true ? <div className="py-5 text-center">
                                    <h4 className="text-gradient text-success"><i className="mdi mdi-check me-2"></i>Message Sent Successfully</h4>
                                    <p className="lead px-6">We will get back to you shortly at {state.email}. Your reference number is {state.reference}</p>
                                </div> : null}
                                
                                {/* <pre>{JSON.stringify(state, ' ', ' ')}</pre> */}
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <CallAction wrapperClass="mt-6" />
        </>
    );
}
 
export default Contact;