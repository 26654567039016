import { Link } from "react-router-dom";

const FaqSection = () => {

    return (
        <section className="section py-6 bg-white">

            <div className="container py-6 text-center" style={{ position: 'relative', zIndex: 2 }}>
                <h2 className="display-6 text-dark">Frequently Asked Questions</h2>
                <p className="mb-5 lead">A <Link to="/software" className="text-reset highlight">Proven Solution</Link>, it's Super Easy, it's Secure and comes with the <Link to="/portfolio" className="text-reset highlight">Best Support</Link>.</p>

                <div className="row justify-content-center">
                    <div className="col-12 col-md-9 col-lg-7">

                        <div className="accordion accordion-flush" id="accordionFAQ">

                            {
                                questionsAnswers.map((item, index) => (
                                    <div className="accordion-item " key={index}>
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={`#faq-collapse-${index}`}>{item.question}</button>
                                        </h2>

                                        <div className={`accordion-collapse collapse`} id={`faq-collapse-${index}`} data-bs-parent="#accordionFAQ">
                                            <div className="accordion-body text-start" dangerouslySetInnerHTML={{ __html: item.answer }}></div>
                                        </div>

                                    </div>
                                ))
                            }

                        </div>

                    </div>
                </div>

            </div>
        </section>
    );
}

export default FaqSection;


const questionsAnswers = [
    // {
    //     question: "What is included in the 'Lifetime Plan'",
    //     answer:
    //         `Within 14 days of purchase, if for whatever reason you are not completely satisfied with your Screens solution, let us know by sending an email to ${getSupportEmail()} and you can arrange to send the full/complete set of the hardware to the Ounch’s registered business address. <a href="${process.env.PUBLIC_URL}/legal/money-back">T&C applies. Please read full text</a>.`
    // },
    {
        question: "What is Digital Signage?",
        answer:
            `Digital signage is the modern-day replacement to traditional, static signage and is more cost-effective and flexible than other forms of visual communications. Digital signage attracts viewers’ attention, creates ambience, informs, educates, and influences purchase decision in point of sale, point of wait, and point of transit environments.`
    },
    {
        question: "Why Should Digital Signage Matter to You?",
        answer:
            `Digital signage is the most effective method for communicating with your audience. Digital signage captures people's attention regardless of location or use. Let us just admit it: static banners, posters, and brochures simply aren't as engaging or effective as digital signage.`
    },
    {
        question: "Why Choose Screenplify?",
        answer:
            `Here's how Screenplify sets itself apart from other solutions and <a href="${process.env.PUBLIC_URL}/software" class="text-reset highlight">makes it the easiest choice</a> for your Digital Signage needs:
                <ul class="pt-4">
                    <li>Highly customizable: Interactive/non-interactive digital screen capabilities with landscape or portrait orientation.</li>
                    <li>Advanced features: Content display scheduling and multiple screen synchronization.</li>
                    <li>Simple and intuitive Layout Editor Tool: drag-and-drop user interface.</li>
                    <li>Fast and easy digital screen deployment, plug-and-install.</li>
                    <li>Hassle-free content update, ability to update screen content remotely or on-site.</li>
                    <li>Monitor deployed screens' statuses for easy troubleshooting and maintenance.</li>
                    <li>From 1 to 3 years (depending on device) warranty for hardware, you can purchase extended warranty upto 3 years if not included.</li>
                </ul>
            `        
    },
    {
        question: "How can I remotely manage my devices?",
        answer: `You can effortlessly manage your digital signage devices from any browser, at any time and instantly send updates to your screen. <a href="${process.env.PUBLIC_URL}/software" class="text-reset highlight">Screenplify’s CMS</a> allows you to update as many screens as you wish from anywhere. And most importantly within minutes of making an update, the content updates.`,
    },
    {
        question: "What type of TV should I use?",
        answer: `Screenplify will work with any TV or any screen of any brand or size that has an HDMI input. But for best visual effect we do have some recommendations. Give us a call, we are more than happy to advice.`,
    },
    {
        question: "Don't worry! We are always here for you.",
        answer: `Email support is available 24/7. Chat and phone lines are open during normal business hours.`,
    },
];