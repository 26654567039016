import React from 'react';

import './index.scss';

import DeviceIconCPU from './spec-icons/processor-blk.svg';
import DeviceIconHDD from './spec-icons/harddrive-blk.svg';
import DeviceIconDOS from './spec-icons/laptop-blk.svg';
import DeviceIconMEM from './spec-icons/memory-blk.svg';
import DeviceIconGFX from './spec-icons/videocard-blk.svg';
import DeviceIconPRT from './spec-icons/ports-blk.svg';

const ProductSpec = ({ cpu, os, hdd, ram, gfx, ports }) => {
    return (
        <ul className="list-group list-group-flush device-spec-list">
            <li className="list-group-item position-relative ps-5">
                <div className="device-spec-icons position-absolute">
                    <img alt="device-spec-icon" src={DeviceIconCPU} />
                </div>
                <span>{cpu}</span>
            </li>
            <li className="list-group-item position-relative ps-5">
                <div className="device-spec-icons position-absolute">
                    <img alt="device-spec-icon" src={DeviceIconDOS} />
                </div>
                <span>{os}</span>
            </li>
            <li className="list-group-item position-relative ps-5">
                <div className="device-spec-icons position-absolute">
                    <img alt="device-spec-icon" src={DeviceIconHDD} />
                </div>
                <span>{hdd}</span>
            </li>
            <li className="list-group-item position-relative ps-5">
                <div className="device-spec-icons position-absolute">
                    <img alt="device-spec-icon" src={DeviceIconMEM} />
                </div>
                <span>{ram}</span>
            </li>
            <li className="list-group-item position-relative ps-5">
                <div className="device-spec-icons position-absolute">
                    <img alt="device-spec-icon" src={DeviceIconGFX} />
                </div>
                <span>{gfx}</span>
            </li>
            <li className="list-group-item position-relative ps-5">
                <div className="device-spec-icons position-absolute">
                    <img alt="device-spec-icon" src={DeviceIconPRT} />
                </div>
                <span>{ports}</span>
            </li>
        </ul>
    );
}
 
export default ProductSpec;