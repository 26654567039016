import React, { useEffect, useRef } from 'react';
import { Link, NavLink } from "react-router-dom";

import './index.scss';

// import { ReactComponent as Logo } from '../../assets/logo-screens.svg';
import { ReactComponent as MenuIcon } from '../../assets/menu.svg';
import { ReactComponent as FullLogo } from '../../assets/screenplify_logo.svg';
import { ResourceLinks, SocialLinks } from '../../Resources';


const MainNav = () => {


    const navBar = useRef(null);
    const offset = 90;

    // const location = useLocation();
    // const { pathname } = location;
    // const splitLocation = pathname.split("/");


    useEffect(() => {
        
        const handleScroll = () => {
            
            if (navBar.current) {
                const winOffset = window.scrollY;
                
                // Toggle Class
                navBar.current.classList[winOffset >= offset ? 'add' : 'remove']('nav-shrink');
            }
        }
        
        window.addEventListener("scroll", handleScroll);
        
        handleScroll();

    }, []);

    // const checkLendActive = (match, location) => {
    //     return location.pathname.indexOf('lend') > 0 ? true : false; 
    // }

    return (
        <>
        <nav ref={navBar} id="main-nav" className="navbar fixed-top navbar-expand-lg navbar-light">
            <div className="container">
                <a className="navbar-brand" href="/">
                    <FullLogo />
                </a>
                
                {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="menu-icon">
                        <MenuIcon/>
                    </span>
                </button> */}

                <button className="navbar-toggler" data-bs-toggle="offcanvas" href="#mainNavOffcanvas" aria-controls="offcanvasExample">
                    <span className="menu-icon">
                        <MenuIcon/>
                    </span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink activeClassName="active text-gradient text-primary" exact className="nav-link px-3 px-md-4 text-center" to="/">Home</NavLink>
                        </li>
                        {/* <li className="nav-item">
                            <NavLink activeClassName="active text-gradient text-primary" className="nav-link px-3 px-md-4 text-center" to="/solutions">Solutions</NavLink>
                        </li> */}

                        <li className="nav-item dropdown">
                            <button className={`nav-link px-3 px-md-4 text-center btn btn-link dropdown-toggle`} data-bs-toggle="dropdown" aria-expanded="false">Product</button>
                            <div className="dropdown-menu border-0 p-3 shadow-lg product-menu ms-n5 rounded-3" aria-labelledby="navbarDropdown">
                                <div className="row">
                                    <div className="col">
                                        <Link to="/software" className="d-block h-100">
                                            <div className="bg-gradient-primary rounded-3 h-100 p-3 position-relative text-white">
                                                <i className="mdi mdi-rocket-launch position-absolute top-0 end-0 mdi-48px opacity-50 mt-n2 me-2"></i>
                                                <p className="lead ftw-600">Screenplify Features</p>
                                                    <p className="m-0">The features that give us the edge.</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col">
                                        <Link to="/hardware" className="d-block h-100">
                                            <div className="bg-gradient-warning a-style rounded-3 h-100 p-3 text-white position-relative">
                                                <i className="mdi mdi-monitor-dashboard position-absolute top-0 end-0 mdi-48px opacity-50 mt-n2 me-2"></i>
                                                <p className="lead ftw-600">Supported Hardware</p>
                                                <p className="m-0">A range of tried and tested devices.</p>
                                            </div>
                                        </Link>
                                    </div>
                                    {/* <div className="col">
                                        <Link to="/usecases" className="d-block h-100">
                                            <div className="bg-gradient-info a-style a-style rounded-3 h-100 p-3 text-white position-relative">
                                                <i className="mdi mdi-image-size-select-large position-absolute top-0 end-0 mdi-48px opacity-50 mt-n2 me-2"></i>
                                                <p className="lead ftw-600">Use Cases</p>
                                                <p className="m-0">Where it can be useful and effective.</p>
                                            </div>
                                        </Link>
                                    </div> */}
                                </div>

                                <hr className="opacity-5" />
                                
                                <div className="row">
                                    <div className="col">
                                        <ul className="list-unstyled">
                                            <li className="nav-item">
                                                <Link to="/usecases" className="nav-link px-2">Use Cases</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/decision-guide" className="nav-link px-2">Decision Guide</Link>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link px-2" href={ResourceLinks.plugin}>Plugins <i className="mdi mdi-call-made"></i></a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link px-2" href={ResourceLinks.templates}>Templates <i className="mdi mdi-call-made"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </li>
                        
                        <li className="nav-item">
                            <NavLink activeClassName="active text-gradient text-primary" className="nav-link px-3 px-md-4 text-center" to="/portfolio">Portfolio</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink activeClassName="active text-gradient text-primary" className="nav-link px-3 px-md-4 text-center" to="/partners">Partners</NavLink>
                        </li>

                        <li className="nav-item dropdown">
                                <button className={`nav-link px-3 px-md-4 text-center btn btn-link dropdown-toggle`} data-bs-toggle="dropdown" aria-expanded="false">Resources</button>
                            <ul className="dropdown-menu border-0 p-3 shadow-lg" aria-labelledby="navbarDropdown">
                                <li className="nav-item">
                                    <a className="nav-link px-3 text-start" href={ResourceLinks.blog}>Blog <i className="mdi mdi-call-made"></i></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link px-3 text-start" href={ResourceLinks.plugin}>Plugins <i className="mdi mdi-call-made"></i></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link px-3 text-start" href={ResourceLinks.templates}>Templates <i className="mdi mdi-call-made"></i></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link px-3 text-start" href={SocialLinks.youtube}>YouTube Videos <i className="mdi mdi-call-made"></i></a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link px-3 text-center" href={ResourceLinks.ResourceLinks}>Reviews <i className="mdi mdi-call-made"></i></a>
                                </li> */}
                            </ul>
                        </li>
                        
                        <li className="nav-item d-flex align-items-center">
                            <a href={ResourceLinks.app} className="nav-link px-3 px-md-4 text-center">Login</a>
                        </li>
                        
                        <li className="nav-item d-flex align-items-center">
                            <NavLink className="btn btn-primary bg-gradient-info border-0 px-3 px-md-4 py-2" to="/contact">Request a Demo</NavLink>
                        </li>

                        {/* <li className="nav-item dropdown">
                            <button className={`nav-link px-3 px-md-4 text-center text-uppercase btn btn-link dropdown-toggle`} data-bs-toggle="dropdown" aria-expanded="false">{i18n.language}</button>
                            <ul className="dropdown-menu border-0 p-3 shadow-lg" aria-labelledby="navbarDropdown">
                                <li className="nav-link px-3 px-md-4 text-center" style={{cursor: 'pointer'}} onClick={() => i18n.changeLanguage('en')}>EN</li>
                                <li className="nav-link px-3 px-md-4 text-center" style={{cursor: 'pointer'}} onClick={() => i18n.changeLanguage('bm')}>BM</li>
                            </ul>
                        </li> */}

                        {/* <li className="nav-item px-3">
                            <a className="btn btn-primary px-3 px-md-4 text-center" target="_blank" rel="noreferrer" href="//app.screens.my">Login</a>
                        </li> */}
                    </ul>
                </div>

            </div>
        </nav>

            <div className="offcanvas offcanvas-start" tabIndex="-1" id="mainNavOffcanvas" aria-labelledby="mainNavOffcanvasLabel">
                <div className="offcanvas-header">
                    <h5 className="full-logo"><FullLogo /></h5>
                    <button className="btn-close text-reset px-4" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body small">
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <NavLink activeClassName="active" exact className="" to="/">Home</NavLink>
                        </li>
                        <li className="list-group-item">
                            <NavLink activeClassName="active" exact className="" to="/software">Software</NavLink>
                        </li>
                        <li className="list-group-item">
                            <NavLink activeClassName="active" className="" to="/hardware">Hardware</NavLink>
                        </li>
                        <li className="list-group-item">
                            <NavLink activeClassName="active" exact className="" to="/portfolio">Portfolio</NavLink>
                        </li>
                        <li className="list-group-item">
                            <NavLink activeClassName="active" className="" to="/partners">Partners</NavLink>
                        </li>
                        <li className="list-group-item">
                            <NavLink activeClassName="active" exact className="" to="/usecases">Use Cases</NavLink>
                        </li>
                        <li className="list-group-item">
                            <NavLink activeClassName="active" className="" to="/decision-guide">Decision Guide</NavLink>
                        </li>
                    
                    </ul>
                    <div className="offcanvas-footer d-flex p-4">
                        <a href={ResourceLinks.app} className="btn btn-link text-reset">Login</a>
                        <NavLink className="btn btn-primary bg-gradient-info border-0 px-3 px-md-4 ms-auto" to="/contact">Request a Demo</NavLink>
                    </div>
                </div>
            </div>

        </>
    );
}
 
export default MainNav;


// https://stackoverflow.com/questions/47879663/root-navlink-always-get-active-class-react-router-dom


/* const SolutionsNavItems = ({listClass, itemClass}) => {
    return (
        <>
            <li className={listClass}><NavLink activeClassName="active" className={itemClass} to="/solutions/covid19">Covid 19</NavLink></li>
            <li className={listClass}><NavLink activeClassName="active" className={itemClass} to="/solutions/retail">Retail</NavLink></li>
            <li className={listClass}><NavLink activeClassName="active" className={itemClass} to="/solutions/restaurants">Restaurants</NavLink></li>
            <li className={listClass}><NavLink activeClassName="active" className={itemClass} to="/solutions/hospitality">Hospitality</NavLink></li>
            <li className={listClass}><NavLink activeClassName="active" className={itemClass} to="/solutions/corporate">Corporate</NavLink></li>
            <li className={listClass}><NavLink activeClassName="active" className={itemClass} to="/solutions/healthcare">Healthcare</NavLink></li>
            <li className={listClass}><NavLink activeClassName="active" className={itemClass} to="/solutions/education">Education</NavLink></li>
        </>
    )
} */