import React from 'react'
import { ResourceLinks } from '../../Resources';

const BlogStrip = () => {
    return (
        <section className="section py-6">
            <div className="container">
                <div className="row pt-5 pb-4 ">
                    <div className="col-md-6 text-center text-md-start">
                        <h4>Dont miss out!</h4>
                        <h2 className="text-gradient text-warning display-6 mb-4">Latest From Our Blog</h2>
                        <p className="lead">Ideas, Inspiration, Concepts, How-Tos' and many more...</p>
                    </div>

                    <div className="col-md-6 d-flex align-items-center justify-content-center justify-content-md-end">
                        <a href={ResourceLinks.blog} className="btn btn-warning bg-gradient-warning border-0 btn-lg text-white px-4">Checkout our blog</a>
                    </div>
                </div>
            </div>

            <div className="bg-light py-6">
                <div className="container">
                    <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4 justify-content-center">
                        <Post
                            title="Event Spaces as a Service: Smart Event Venues"
                            link="https://blog.screenplify.com/event-spaces-as-a-service-smart-event-venues/"
                            image="/blog/sunway-main-300x169.jpg"
                            name="Ali Lishan"
                            date="July 13, 2020" />
                        
                        <Post
                            title="Using Digital Signage for Internal Communications"
                            link="https://blog.screenplify.com/using-digital-signage-for-internal-communications/"
                            image="/blog/allianz-300x169.png"
                            name="Ali Lishan"
                            date="February 5, 2020" />          

                        <Post
                            title="Mydin deploys over 100 Screenplify screens and kiosks nationwide"
                            link="https://blog.screenplify.com/mydin-deploys-over-a-100-screeplify-screens-and-kiosks-nationwide/"
                            image="/blog/mydin_hypermarket-300x169.jpg"
                            name="Ali Lishan"
                            date="February 5, 2020" />

                    </div>
                </div>
            </div>

        </section>
    );
}
 
export default BlogStrip;

const Post = ({title, link, image, name, date}) => {
    return (
        <div className="col mb-3">
            <div className="card border-0 shadow h-100">
                <img src={`${process.env.PUBLIC_URL}${image}`} className="card-img-top" alt="..." />
                <div className="card-body d-flex flex-column">
                    <p>By {name} <small>{date}</small></p>
                    <p className="lead text-dark">{title}</p>

                    <div className="mt-auto">
                        <a href={link} className="btn btn-info bg-gradient-info border-0 text-white">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    )
}